import React, { useState, useEffect, useCallback } from 'react';
import styles from '../../assets/css/Patient.module.css'
import { getTokenFromLocalStorage, formatProviderName, authorizedTitles } from '../../helpers';
import axios from 'axios';
import { toast } from 'react-toastify';
import AddMultiplePatients from './AddMultiplePatients';

const GetMultiplePatientsData = () => {
    const [loading, setLoading] = useState(false);
    const [facesheets, setFacesheets] = useState([]);
    const [visittype, setVisitType] = useState('');
    const [patients, setPatients] = useState([]);
    const [owningProvider, setOwningProvider] = useState('');
    const [authorizedProviders, setAuthorizedProviders] = useState([]);

    const fetchData = useCallback(async () => {
        try {
            const requestOptions = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                }
            };
            const [userResponse, providersResponse] = await Promise.all([
                fetch(`${process.env.REACT_APP_API_URL}/users/user-details`, requestOptions),
                fetch(`${process.env.REACT_APP_API_URL}/users/authorized-providers`, requestOptions)
            ]);

            const userData = await userResponse.json();
            const providers = await providersResponse.json();

            if (providersResponse.ok && userResponse.ok) {
                setAuthorizedProviders(providers);
                if (authorizedTitles.includes(userData.title)) {
                    setOwningProvider(userData.id);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;

        const validFiles = files.filter(file => {
            if (!allowedExtensions.exec(file.name)) {
                toast.error(`${file.name} is not a valid file type`);
                return false;
            }
            return true;
        });

        setFacesheets(validFiles);
    };

    const uploadFacesheet = async () => {
        if (facesheets.length === 0) {
            toast.error('Please select facesheet files');
            return;
        }

        if (visittype === '') {
            toast.error('Please select a visit type');
            return;
        }

        if (owningProvider === '') {
            toast.error('Please select owning provider');
            return;
        }

        try {
            setLoading(true);

            const uploadPromises = facesheets.map(file => {
                const formData = new FormData();
                formData.append('facesheet', file);
                formData.append('visittype', visittype);
                formData.append('WillUploadFaceSheet', "True");
                formData.append('owning_provider_id', owningProvider);

                return axios.post(`${process.env.REACT_APP_API_URL}/users/upload-facesheet`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                    }
                });
            });

            const results = await Promise.all(uploadPromises);
            const patientsArray = [];
            results.forEach(result => {
                const patient = result.data;
                patientsArray.push(patient);
            });
            setPatients(patientsArray);
            toast.success('All facesheets uploaded successfully');
            setFacesheets([]);

        } catch (error) {
            toast.error('Error uploading some facesheets');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.content}>
            {patients.length > 0 ? <AddMultiplePatients patients={patients} owningProvider={owningProvider} /> : <div style={{ height: '100%', gap: '1.5rem', justifyContent: 'center' }} className={styles.subContent}>
                <div style={{ marginBottom: '1.5rem', marginTop: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <label htmlFor="owningProvider">Owning Provider<span className={styles.required}>*</span></label>
                    <select
                        className={styles.selectInputField}
                        value={owningProvider}
                        onChange={(e) => setOwningProvider(e.target.value)}
                        required
                    >
                        <option value="">Select Owning Provider</option>
                        {authorizedProviders.map(provider => {
                            const fullName = formatProviderName(provider);
                            return (
                                <option
                                    key={provider.id}
                                    value={provider.id}
                                >
                                    {fullName}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <h3>Upload Multiple Facesheets</h3>
                <div className={styles.rowContainer}>
                    <div style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: '1px solid var(--placeholder-grey)' }} onClick={() => setVisitType('inpatient')} className={`${styles.inactiveButton} ${visittype === 'inpatient' ? styles.activeButton : ''}`}>Inpatient</div>
                    <div style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => setVisitType('consult')} className={`${styles.inactiveButton} ${visittype === 'consult' ? styles.activeButton : ''}`}>Consult</div>
                </div>
                <input type="file" multiple accept="image/*,application/pdf" onChange={handleFileChange} />
                {facesheets.length > 0 && (
                    <div>Selected files: {facesheets.length}</div>
                )}
                {loading ?
                    <div>Uploading facesheets...</div> :
                    <div style={{ width: '200px' }} onClick={uploadFacesheet} className="purple-btn">
                        Upload Facesheets
                    </div>
                }
            </div>}
        </div>
    );
};

export default GetMultiplePatientsData;