import React from 'react';

const Note = () => {
    return (
        <div>
            Note
        </div>
    );
};

export default Note;