import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo_single.png';
import '../../assets/css/DesktopHomeNavbar.css';

export const DesktopHomeNavbar = () => {
  return (
    <div className='DesktopNavbarContainer'>
      <img className='NavbarLogo' src={logo} alt="Logo" />
      <div className='NavbarLinks'>
        <NavLink to={process.env.REACT_APP_HOME_DOMAIN} className='NavbarLink'>Home</NavLink>
      </div >
      <div className='NavbarLinks'>
      </div>
    </div >
  )
};
