import React from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import styles from '../../assets/css/Profile.module.css';
import { ReactComponent as Padlock } from '../../assets/icons/padlock_.svg';
import { ReactComponent as Notification } from '../../assets/icons/notification_.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/right-arrow.svg';
import { useOutletContext } from 'react-router-dom';

const SecurityAndPrivacy = () => {
    const { userData } = useOutletContext();
    const navigate = useNavigate();
    const location = useLocation();
    const isMainView = location.pathname === '/profile/security-privacy';

    const handleCardClick = (route) => {
        navigate(`/profile/security-privacy/${route}`);
    };

    return (
        <div className={styles.securityAndPrivacyContainer}>
            {isMainView ? (
                <div className={styles.cardContainer}>
                    <div
                        className={styles.card}
                        onClick={() => handleCardClick('notification')}
                    >
                        <div className={styles.cardIcon}>
                            <Notification width='25' hieght='25' />
                        </div>
                        <div className={styles.cardDetails}>
                            <h3>Notifications</h3>
                            <p>Manage email and SMS notifications </p>
                        </div>
                        <div className={styles.cardArrowIcon}>
                            <RightArrow width='20' hieght='20' fill='grey' />
                        </div>
                    </div>
                    <div
                        className={styles.card}
                        onClick={() => handleCardClick('change-password')}
                    >
                        <div className={styles.cardIcon}>
                            <Padlock width='25' hieght='25' />
                        </div>
                        <div className={styles.cardDetails}>
                            <h3>Change Password</h3>
                            <p>Update your password to ensure security and maintain access</p>
                        </div>
                        <div className={styles.cardArrowIcon}>
                            <RightArrow width='20' hieght='20' fill='grey' />
                        </div>
                    </div>
                </div>
            ) : (
                <Outlet context={{ userData }} />
            )}
        </div>
    );
};

export default SecurityAndPrivacy;
