import React, { useEffect, useState } from 'react';
import Scheduler from '../reusable/Scheduler';
import { getTokenFromLocalStorage } from '../../helpers';
import Loader from './Loader';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import styles from '../../assets/css/Utilities.module.css'
import { useNavigate } from 'react-router-dom';

const EmployeeCalendar = () => {
    const navigate = useNavigate();
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    window.scheduler.loading = false;

    useEffect(() => {
        if (sessionStorage.getItem('refreshScheduler') === 'true') {
            sessionStorage.removeItem('refreshScheduler');
            window.location.reload();
        }
        else {
            fetchCalendarData();
        }
    }, []);

    const handleEventAdded = () => {
        fetchCalendarData();
    };

    const fetchCalendarData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/employee-calendar`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                },
            });
            if (response.ok) {
                const data = await response.json();
                setCalendarEvents(data);
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            {loading ? <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loader width={100} height={100} /></div> :
                <div className="UtilitiesContainer">
                    <div className='NavigationContainer'>
                        <BackIcon onClick={() => { navigate(-1) }} className="back-button" />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} className={[styles.calendarPadding, 'UtilitiesMainContainer whiteContainerWide'].join(' ')}>
                        <Scheduler events={calendarEvents} onEventAdded={handleEventAdded} />
                    </div>
                </div>
            }
        </>
    );
};

export default EmployeeCalendar;