import React, { useEffect, useState } from 'react'
import { getTokenFromLocalStorage, isValidDate, convertToCustomDate } from '../../helpers';
import styles from '../../assets/css/ChargesTab.module.css'
import styles2 from '../../assets/css/SubmittedChargesHistory.module.css'
import SubmittedChargesHistoryTable from '../reusable/SubmittedChargesHistoryTable';
import Loader from '../reusable/Loader';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg'

const SubmittedChargesHistory = () => {

    const [chargesHistory, setChargesHistory] = useState([]);
    const [patients, setPatients] = useState([]);
    const [hideTable, setHideTable] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchSubmittedChargesHistory();
    }, [patients]);

    const fetchSubmittedChargesHistory = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenFromLocalStorage()}`
            }
        };

        const res = await fetch(`${process.env.REACT_APP_API_URL}/users/submitted-charges-history`, requestOptions);
        const data = await res.json();
        setChargesHistory(data);
        const groupedByTimestamp = data.reduce((acc, obj) => {
            const timestamp = obj.timestamp;
            if (!acc[timestamp]) {
                acc[timestamp] = [];
            }
            acc[timestamp].push(obj);
            return acc;
        }, {});
        setChargesHistory(groupedByTimestamp);
    };

    const fetchPatientChargesHistory = async (patientsData) => {
        try {
            let promiseArray = [];
            patientsData.forEach(patient => {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                    }
                };
                promiseArray.push(fetch(`${process.env.REACT_APP_API_URL}/users/patient-charges-history?patient_charges_history_id=${patient.patient_charges_history_id}&patientId=${patient.patient_id}`, requestOptions));
            })
            setLoading(true);
            let result = await Promise.all(promiseArray);
            const data = await Promise.all(result.map(res => res.json()));
            const flattenedData = data.flatMap(array => array);
            const uniqueData = [...new Set(flattenedData)];
            setPatients(uniqueData);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const toggleTable = (patients) => {
        setPatients(patients);
        setHideTable(!hideTable);
        if (hideTable) {
            fetchPatientChargesHistory(patients);
        }
    };

    return (<>
        <div className={[styles2.backButton, 'NavigationContainer'].join(' ')}>
            {!hideTable && <div className="back-button-container">
                <BackIcon onClick={() => toggleTable([])} className="back-button" />
            </div>}
        </div>
        <div className={styles.tableContainer}>
            <div className={styles.flexTable}>
                {hideTable && <div id="tableHeader" className={styles.flexRow}>
                    <div className={styles.flexCell} style={{ flex: 0.2 }}>Index</div>
                    <div className={[styles.flexCell].join(' ')} style={{ flex: 0.3 }}>Date of Service</div>
                    <div className={[styles.flexCell].join(' ')} style={{ flex: 0.2 }}>Total Charges</div>
                    <div className={[styles.flexCell].join(' ')} style={{ flex: 0.2 }}>Timestamp</div>
                </div>}

                {loading ? <Loader /> :
                    hideTable ?
                        Object.entries(chargesHistory).map(([timestamp, group], index) => (
                            <div key={index} style={{ padding: '0 0.3rem' }} onClick={() => toggleTable(chargesHistory[timestamp])} className={[styles.flexRow].join(' ')}>
                                <div className={styles.flexCell} style={{ flex: 0.2 }}><Eye style={{ height: '15px', width: '15px' }} fill={'var(--purple)'} />&nbsp; {index}</div>
                                <div className={[styles.flexCell].join(' ')} style={{ flex: 0.3 }}>{isValidDate(chargesHistory[timestamp][0].date_of_service) && convertToCustomDate(chargesHistory[timestamp][0].date_of_service)}</div>
                                <div className={[styles.flexCell].join(' ')} style={{ flex: 0.2 }}>{group.length}</div>
                                <div className={[styles.flexCell].join(' ')} style={{ flex: 0.2 }}>{new Date(chargesHistory[timestamp][0].timestamp).toLocaleString()}</div>
                            </div>
                        )) : <SubmittedChargesHistoryTable handleTableChange={() => toggleTable()} patients={patients} />}

            </div>
        </div>
    </>
    )

}

export default SubmittedChargesHistory