import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from "../../assets/css/SignIn.module.css";
import { storeTokenInLocalStorage } from '../../helpers';

const SignIn = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = localStorage.getItem('token');
        if (accessToken) {
            navigate('/patient-list');
        }
    }, [navigate]);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [rememberMe, setRememberMe] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    useEffect(() => {
        const rememberedEmail = localStorage.getItem('rememberedEmail');
        const rememberedPassword = localStorage.getItem('rememberedPassword');
        if (rememberedEmail && rememberedPassword) {
            setEmail(rememberedEmail);
            setPassword(rememberedPassword);
            setRememberMe(true);
        }
    }, []);

    const handleSubmitSignIn = (e) => {
        let errors = {};
        e.preventDefault();

        if (rememberMe) {
            localStorage.setItem('rememberedEmail', email);
            localStorage.setItem('rememberedPassword', password);
        } else {
            localStorage.removeItem('rememberedEmail');
            localStorage.removeItem('rememberedPassword');
        }

        if (!email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is not valid';
        }
        // else if (!email.endsWith('@gmail.com')) {
        //     errors.email = 'Email must be a Gmail address';
        // }

        if (!password) {
            errors.password = 'Password is required';
        } else if (password.length < 8) {
            errors.password = 'Password must be at least 8 characters';
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        setErrors({});
        setLoading(true);

        const headers = {
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ email, password })
        };

        fetch(`${process.env.REACT_APP_API_URL}/users/signin`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.token) {
                    storeTokenInLocalStorage(data.token);
                    toast.success('Signed in successfully');
                    setTimeout(() => {
                        navigate('/patient-list');
                    }, 1000);
                } else {
                    setLoading(false);
                    toast.error('Invalid email or password');
                    setTimeout(() => {
                        navigate('/signin');
                    }, 3000);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Network error');
            });

    };

    const handleForgotPassword = async () => {
        if (!email.trim()) {
            toast.error('Please enter your email first');
            return;
        }

        setShowForgotPassword(true);
        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email })
            });

            const data = await response.json();
            if (response.ok) {
                toast.success('Password reset link sent to your email');
                setTimeout(() => {
                    alert('Please check your spam folder if you don\'t see the email in your inbox');
                }, 3000);
            } else {
                toast.error(data.message || 'Failed to send reset link');
            }
        } catch (error) {
            toast.error('Network error');
        } finally {
            setLoading(false);
            setShowForgotPassword(false);
        }
    };


    return (
        <section className={styles.loginContainer}>
            <div className={styles.loginContent}>
                {loading && showForgotPassword ? (
                    <div className={styles.loading}>Sending reset link...</div>
                ) : (
                    <form onSubmit={handleSubmitSignIn} className={styles.loginForm}>
                        <div className={styles.signInButtonWrapper}>
                            <div className={styles.welcomeText}>Welcome back</div>
                            <div className={styles.signInText}>Please sign in to your account</div>
                        </div>
                        {
                            !loading ?
                                <>
                                    <div className={styles.inputWrapper}>
                                        <input
                                            className={styles.inputField}
                                            placeholder="Email"
                                            type="text"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value.toLowerCase())} />
                                        {errors.email && <div className={styles.error}>{errors.email}</div>}

                                        <input
                                            className={styles.inputField}
                                            placeholder="Password"
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)} />
                                        {errors.password && <div className={styles.error}>{errors.password}</div>}
                                        <div className={styles.rememberForgotWrapper}>
                                            <label className={styles.rememberMeLabel}>
                                                <input
                                                    type="checkbox"
                                                    checked={rememberMe}
                                                    onChange={(e) => setRememberMe(e.target.checked)}
                                                />
                                                Remember Me
                                            </label>
                                            <button
                                                type="button"
                                                className={styles.forgotPasswordButton}
                                                onClick={handleForgotPassword}
                                            >
                                                Forgot Password?
                                            </button>
                                        </div>
                                    </div>
                                    <button className={styles.submitButton}>Submit</button><div className={styles.signInBottomBox}>
                                        <p>Don't have an account? <Link className={styles.signInBottomLink} to="/signup">Sign Up</Link></p>
                                    </div>
                                </>
                                : <div className={styles.loading}>Loading...</div>
                        }
                    </form>
                )}
            </div>
        </section >
    );
};

export default SignIn;