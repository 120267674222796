import React, { useState } from 'react';
import { ReactComponent as Tick } from '../../assets/icons/facesheet_tick.svg'
import { ReactComponent as Cross } from '../../assets/icons/facesheet_cross.svg'
import { ReactComponent as Eye } from '../../assets/icons/eye.svg'
import styles from '../../assets/css/PatientList.module.css'
import { useNavigate } from 'react-router-dom'
import { convertToCustomDate, getTokenFromLocalStorage, isValidDate, capitalizeNames } from '../../helpers'

const TableRow = ({ patient, isEditMode, setPatients }) => {
    const navigate = useNavigate();
    const [hoverContent, setHoverContent] = useState(null);

    const handleMouseEnter = () => {
        if (patient.diagnoses?.length > 0) {
            setHoverContent(
                patient.diagnoses.map((diagnosis, index) => (
                    <div key={index}>
                        {diagnosis.code} - {diagnosis.description}
                    </div>
                ))
            );
        }
    };

    const handleMouseLeave = () => {
        setHoverContent(null);
    };

    const EditDetails = (patient) => {
        navigate('/patient', { state: { patient: patient, mode: 'view&edit', autoFillChoice: true } });
    };

    const handleStatusToggle = async () => {
        const newStatus = patient.status === 'active' ? 'inactive' : 'active';
        const formData = new URLSearchParams();

        if (patient.id) formData.append('id', patient.id);
        if (patient.firstname) formData.append('firstname', patient.firstname);
        if (patient.lastname) formData.append('lastname', patient.lastname);
        if (patient.middlename) formData.append('middlename', patient.middlename);
        if (patient.dateofbirth) formData.append('dateofbirth', patient.dateofbirth);
        if (patient.room) formData.append('room', patient.room);
        if (patient.location) formData.append('location', patient.location);
        if (patient.admitdate) formData.append('admitdate', patient.admitdate);
        if (patient.dischargedate) formData.append('dischargedate', patient.dischargedate);
        if (patient.visittype) formData.append('visittype', patient.visittype);
        formData.append('status', newStatus);
        if (patient.facesheetalias) formData.append('facesheetalias', patient.facesheetalias);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/add-patient`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer " + getTokenFromLocalStorage(),
                },
                body: formData.toString(),
            });

            if (response.ok) {
                setPatients((prevPatients) =>
                    prevPatients.map((p) =>
                        p.id === patient.id ? { ...p, status: newStatus } : p
                    )
                );
            } else {
                throw new Error('Failed to update patient status');
            }
        } catch (error) {
            console.error('Error updating status:', error);
            alert('Error updating status');
        }
    };

    return (
        <div className={styles.flexRow}>
            <div onClick={() => EditDetails(patient)} className={styles.flexCell} style={{ flex: 0.1, marginRight: '4px' }}>
                <Eye fill={'var(--purple)'} className={styles.listIcons} />
            </div>
            <div onClick={() => EditDetails(patient)} className={styles.flexCell} style={{ flex: 0.5 }}>{capitalizeNames(patient.firstname, patient.middlename, patient.lastname)}</div>
            <div onClick={() => EditDetails(patient)} className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.5 }}>{isValidDate(patient.dateofbirth) && convertToCustomDate(patient.dateofbirth)}</div>
            <div onClick={() => EditDetails(patient)} className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.5 }}>{isValidDate(patient.admitdate) && convertToCustomDate(patient.admitdate)}</div>
            <div onClick={() => EditDetails(patient)} className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 1 }}>{patient.location}</div>
            <div onClick={() => EditDetails(patient)} className={styles.flexCell} style={{ flex: 0.4 }}>{patient.visittype !== 'null' ? patient.visittype : ''}</div>
            <div
                onClick={() => EditDetails(patient)}
                className={[styles.flexCell, styles.HideMobile].join(' ')}
                style={{ flex: 0.4 }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {patient.diagnoses?.length > 0
                    ? patient.diagnoses.map(diagnosis => diagnosis.code).join(', ')
                    : ' - '}
                {hoverContent && (
                    <div className='hoverContent1' style={{ transform: 'translate(0%, -70%)' }}>
                        {hoverContent}
                    </div>
                )}
            </div>
            <div className={[styles.flexCell].join(' ')} style={{ flex: 0.3 }}>
                {isEditMode ? (
                    <div
                        className={`${styles.toggleSwitch} ${patient.status === 'active' ? styles.active : styles.inactive}`}
                        onClick={handleStatusToggle}
                    >
                        <div className={styles.toggleButton}></div>
                        <span className={styles.toggleLabel}>
                            {patient.status === 'active' ? 'active' : 'inactive'}
                        </span>
                    </div>
                ) : (
                    patient.status
                )}
            </div>
            <div onClick={() => EditDetails(patient)} className={styles.flexCell} style={{ flex: 0.3, justifyContent: 'center' }}>{patient.facesheetalias ? <Tick fill={'green'} /> : <Cross fill={'red'} />}</div>
        </div >
    )
};

export default TableRow
