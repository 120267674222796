import React, { useState, useEffect } from 'react';
import PatientDetails from './PatientDetails';
import { ReactComponent as PatientDetailsLeftArrow } from '../../assets/icons/PatientDetailsLeftArrow.svg';
import { ReactComponent as PatientDetailsRightArrow } from '../../assets/icons/PatientDetailsRightArrow.svg';
import { toast } from 'react-toastify';
import { addPatient, addPatientIdToChargesPage, saveDiagnosis, formatProviderName } from '../../helpers';
import { convertToCustomDate, getTokenFromLocalStorage, isValidDate, capitalizeNames } from '../../helpers/index.js';
import styles from '../../assets/css/Patient.module.css'
import GenericTable from '../reusable/GenericTable';

const AddMultiplePatients = ({ patients, owningProvider }) => {
    const [patientIndex, setPatientIndex] = useState(0);
    const [addPatientToChargesPage, setAddPatientToChargesPage] = useState(true)
    const [providers, setProviders] = useState([]);
    const isSummaryPage = patientIndex === patients.length;

    const changePatientIndex = (type) => {
        if (type === 'left') {
            if (patientIndex > 0) {
                setPatientIndex(patientIndex - 1);
            }
        } else {
            if (patientIndex < patients.length) {
                const currentPatient = patients[patientIndex];
                if (validateCurrentPatient(currentPatient)) {
                    setPatientIndex(patientIndex + 1);
                }
            }
        }
    }

    useEffect(() => {
        const fetchProviders = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/authorized-providers`, {
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                }
            });
            const data = await response.json();
            setProviders(data);
        };
        fetchProviders();
    }, []);

    const validateCurrentPatient = (patient) => {
        if (!patient.firstname) {
            toast.error('Please enter first name');
            return false;
        }
        if (!patient.lastname) {
            toast.error('Please enter last name');
            return false;
        }
        if (!patient.dateofbirth) {
            toast.error('Please select date of birth');
            return false;
        }
        if (!patient.admitdate) {
            toast.error('Please select admit date');
            return false;
        }
        if (!patient.visittype) {
            toast.error('Please select visit type');
            return false;
        }
        if (!patient.location) {
            toast.error('Please select place of service');
            return false;
        }
        if (!patient.owning_provider_id) {
            toast.error('Please select owning provider');
            return false;
        }
        return true;
    };
    const saveAllPatients = async () => {
        try {

            // Add duplicate check
            for (let i = 0; i < patients.length; i++) {
                for (let j = i + 1; j < patients.length; j++) {
                    if (
                        patients[i].firstname === patients[j].firstname &&
                        patients[i].lastname === patients[j].lastname &&
                        patients[i].dateofbirth === patients[j].dateofbirth
                    ) {
                        toast.error("The same facesheet has been detected in this batch. Please try uploading again. Only upload unique facesheets.");
                        return;
                    }
                }
            }

            const savePatientsPromiseArray = patients.map(p => {
                return addPatient(p.id, p.firstname, p.lastname, p.middlename, p.gender, p.dateofbirth, p.room, p.location, p.admitdate, p.dischargedate, p.visittype, p.status, p.facesheetalias, p.owning_provider_id)
            })
            let result = await Promise.all(savePatientsPromiseArray);
            toast.success('Patients added successfully');
            const patientResponses = await Promise.all(result.map(r => r.json()));

            const diagnosesPromiseArray = patients
                .map((p, index) => {
                    return saveDiagnosis(p.id, p.selectedDiagnosis ? p.selectedDiagnosis : []);
                })

            if (diagnosesPromiseArray.length > 0) {
                result = await Promise.all(diagnosesPromiseArray);
                toast.success('Diagnoses added successfully');
            }

            if (addPatientToChargesPage) {
                const addPatientToChargesPagePromiseArray = patients.map((p, index) => addPatientIdToChargesPage(p.id, patientResponses[index].admission_id));
                result = await Promise.all(addPatientToChargesPagePromiseArray);
                toast.success('Patients added to Charges Page');
            }
        } catch (error) {
            toast.error('An error occurred');
        }
    }
    const columns = [
        {
            key: 'name',
            label: 'Name',
            width: 0.2,
            render: (row) => capitalizeNames(row.firstname, row.middlename, row.lastname)
        },
        {
            key: 'dateofbirth',
            label: 'DOB',
            width: 0.15,
            render: (row) => isValidDate(row.dateofbirth) && convertToCustomDate(row.dateofbirth)
        },
        {
            key: 'admitdate',
            label: 'Admit Date',
            width: 0.15,
            render: (row) => isValidDate(row.admitdate) && convertToCustomDate(row.admitdate)
        },
        {
            key: 'location',
            label: 'Location',
            width: 0.2
        },
        {
            key: 'owning_provider_name',
            label: 'Provider',
            width: 0.2,
            render: (row) => {
                const provider = providers.find(p => Number(p.id) === Number(row.owning_provider_id));
                if (provider) {
                    return formatProviderName(provider);
                }
                return '';
            }
        },
        {
            key: 'visittype',
            label: 'Visit Type',
            width: 0.15
        }
    ];

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div className={styles.summaryPageNavigation}>
                <PatientDetailsLeftArrow fill={patientIndex === 0 ? 'grey' : 'black'} onClick={() => changePatientIndex('left')} />
                <p>{isSummaryPage ?
                    `Summary (${patients.length + 1} / ${patients.length + 1})` :
                    `Patient (${patientIndex + 1} / ${patients.length + 1})`}
                </p>
                <PatientDetailsRightArrow fill={patientIndex === patients.length ? 'grey' : 'black'} onClick={() => changePatientIndex('right')} />
            </div>
            {isSummaryPage ? (
                <div className={styles.summaryPage}>
                    <h2>Summary</h2>
                    <GenericTable columns={columns} data={patients} minWidth='740px' />
                    <div style={{ justifyContent: 'center', marginTop: '2rem' }} className={styles.checkboxContainer}>
                        <input type="checkbox" checked={addPatientToChargesPage} onChange={() => setAddPatientToChargesPage(!addPatientToChargesPage)} />
                        <span>Add all patients to charges page</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                        <div style={{ backgroundColor: 'black' }} onClick={saveAllPatients} className="purple-btn">Save all</div>
                    </div>
                </div>
            ) : (
                <PatientDetails
                    key={patientIndex}
                    patient={patients[patientIndex]}
                    mode={'add'}
                    subMode={'addMultiplePatients'}
                    selectedDiagnosisProps={patients[patientIndex]?.selectedDiagnosis}
                />
            )}
        </div>
    );
};

export default AddMultiplePatients;