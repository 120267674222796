import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../../assets/css/Sidebar.module.css';
import Logo from '../../assets/images/logo1.png';
import RehabPsychLogo from '../../assets/images/rehab_psych_logo.png'
import EvanoProfilePic from '../../assets/images/evano.png';

import { ReactComponent as LeftArrowIcon } from '../../assets/icons/left-arrow.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/right-arrow.svg';
import { ReactComponent as DownArrowHalf } from '../../assets/icons/down-arrow-half.svg';
import { ReactComponent as PatientsIcon } from '../../assets/icons/patients.svg';
import { ReactComponent as PatientsIconMobile } from '../../assets/icons/patients-mobile.svg';
import { ReactComponent as ChargesIcon } from '../../assets/icons/charges.svg';
import { ReactComponent as ChargesIconMobile } from '../../assets/icons/charges-mobile.svg';
import { ReactComponent as SignOutIcon } from '../../assets/icons/logout.svg';
import { ReactComponent as SignOutIconMobile } from '../../assets/icons/logout-mobile.svg';
import { ReactComponent as UtilitiesIcon } from '../../assets/icons/utilities.svg';
import { ReactComponent as UtilitiesIconMobile } from '../../assets/icons/utilitiesMobile.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile.svg';
import { ReactComponent as ProfileIconMobile } from '../../assets/icons/profileMobile.svg';
import { useNavigate } from 'react-router-dom';
import { getTokenFromLocalStorage } from '../../helpers';

const SideBar = ({ isMobile }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const token = useRef(getTokenFromLocalStorage());
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [activeMenu, setActiveMenu] = useState('Patients');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1026) {
                setIsCollapsed(true);
            } else {
                setIsCollapsed(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        if (location.pathname === '/patient-list') {
            setActiveMenu('Patients');
        } else if (location.pathname === '/charges') {
            setActiveMenu('Charges');
        } else if (location.pathname === '/utilities') {
            setActiveMenu('Utilities');
        } else if (location.pathname.startsWith('/profile')) {
            setActiveMenu('Profile');
        } else if (location.pathname === '/calendar') {
            setActiveMenu('Utilities');
        }
        else if (location.pathname === '/appointment') {
            setActiveMenu('Utilities');
        }
        else if (location.pathname === '/consults-tracking-table') {
            setActiveMenu('Utilities');
        }
        else if (location.pathname === '/consult-details') {
            setActiveMenu('Utilities');
        }

        return () => window.removeEventListener('resize', handleResize);

    }, [location.pathname]);

    if (location.pathname === '/' || location.pathname === '/signin' || location.pathname === '/signup' || location.pathname === '/about' || location.pathname === '/services' || location.pathname === '/staff-users') {
        return null;
    }
    if ((getTokenFromLocalStorage() === null)) {
        return null;
    }

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    const handleMenuClick = (menu) => {
        setActiveMenu(menu);
        if (menu === 'Patients') {
            navigate('/patient-list');
        } else if (menu === 'Add New Patient') {
            navigate('/patient');
        } else if (menu === 'Charges') {
            navigate('/charges');
        } else if (menu === 'Sign Out') {
            localStorage.removeItem('token');
            navigate('/signin');
        } else if (menu === 'Profile') {
            navigate('/profile');
        } else if (menu === 'Utilities') {
            navigate('/utilities');
        }
    }

    return (
        <div className={`${isCollapsed || isMobile ? styles.collapsed : ''} ${styles.sidebarContainer}`}>
            <LeftArrowIcon onClick={toggleCollapse} className={`${styles.LeftArrowIcon} ${isCollapsed ? styles.hide : ''}`} />
            <RightArrowIcon onClick={toggleCollapse} className={`${styles.LeftArrowIcon} ${isCollapsed ? '' : styles.hide}`} />
            <div className={`${isCollapsed ? styles.hide : styles.topHeading}`}>
                <div className={styles.imageContainer}>
                    <img src={process.env.REACT_APP_LOGO === 'rehabpsych' ? RehabPsychLogo : Logo} alt="Avatar" className={styles.logo} />
                </div>
            </div>

            <div className={styles.menuList}>
                {
                    process.env.REACT_APP_HOME_DOMAIN !== 'https://app.varehabphysicians.com/' &&
                    <>
                        <div className={`${styles.MenuItemWrapper} ${activeMenu === 'Patients' ? styles.activeMenu : ''}`} onClick={() => handleMenuClick('Patients')}>
                            {isMobile ? <PatientsIconMobile className={activeMenu !== 'Patients' && styles.icon} /> : <PatientsIcon fill={activeMenu === 'Patients' ? 'white' : '#9197B3'} />}
                            <p className={`${isCollapsed ? styles.hide : styles.menuItemText}`}>Patients</p>
                        </div>
                        <div className={`${styles.MenuItemWrapper} ${activeMenu === 'Charges' ? styles.activeMenu : ''}`} onClick={() => handleMenuClick('Charges')}>
                            {isMobile ? <ChargesIconMobile className={activeMenu !== 'Charges' && styles.icon} /> : <ChargesIcon fill={activeMenu === 'Charges' ? 'white' : '#9197B3'} />}
                            <p className={`${isCollapsed ? styles.hide : styles.menuItemText}`}>Charges</p>
                        </div>
                    </>
                }

                <div className={`${styles.MenuItemWrapper} ${activeMenu === 'Utilities' ? styles.activeMenu : ''}`} onClick={() => handleMenuClick('Utilities')}>
                    {isMobile ? <UtilitiesIconMobile className={activeMenu !== 'Utilities' && styles.icon} /> : <UtilitiesIcon fill={activeMenu === 'Utilities' ? 'white' : '#9197B3'} />}
                    <p className={`${isCollapsed ? styles.hide : styles.menuItemText}`}>Utilities</p>
                </div>
                <div className={`${styles.MenuItemWrapper} ${activeMenu === 'Profile' ? styles.activeMenu : ''}`} onClick={() => handleMenuClick('Profile')}>
                    {isMobile ? <ProfileIconMobile className={activeMenu !== 'Profile' && styles.icon} /> : <ProfileIcon stroke={activeMenu === 'Profile' ? 'white' : '#9197B3'} />}
                    <p className={`${isCollapsed ? styles.hide : styles.menuItemText}`}>Profile</p>
                </div>
                <div className={`${styles.MenuItemWrapper} ${activeMenu === 'Sign Out' ? styles.activeMenu : ''}`} onClick={() => handleMenuClick("Sign Out")}>
                    {isMobile ? <SignOutIconMobile className={activeMenu !== 'Sign Out' && styles.icon} /> : <SignOutIcon fill={activeMenu === 'Sign Out' ? 'white' : '#9197B3'} />}
                    <p className={`${isCollapsed ? styles.hide : styles.menuItemText}`}>Sign Out</p>
                </div>
            </div>

            {/* <div className={styles.userDetails}>
                <div className={styles.profilepic}>
                    <img className={styles.userAvatar} src={EvanoProfilePic} alt="" />
                    <div className={`${isCollapsed ? styles.hide : styles.user}`}>
                        <p className={styles.userName}>Evano</p>
                        <p className={styles.userDesignation}>Project Manager</p>
                    </div>
                </div>
                <DownArrowHalf className={`${isCollapsed ? styles.hide : styles.downArrowHalf}`} />
            </div> */}

        </div>
    );
};

export default SideBar;
