import React, { useState } from 'react';
import styles from '../../assets/css/Patient.module.css';
import styles1 from '../../assets/css/ChargesTab.module.css';
import styles2 from '../../assets/css/Admissions.module.css';
import styles3 from '../../assets/css/PatientList.module.css';
import { ReactComponent as SharedIcon } from '../../assets/icons/charges-shared-visit.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import GenericTable from '../reusable/GenericTable.js';
import Loader from '../reusable/Loader.js';
import Note from './Note.js';
import { convertToCustomDate, isValidDate } from '../../helpers/index.js';

const Notes = ({ patient }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 631);
    const [showPatientNote, setShowPatientNote] = useState(false);
    const [hoveredRowId, setHoveredRowId] = useState(null);
    const [currentPatient, setCurrentPatient] = useState(null);

    const onCellClick = (row) => {
        setCurrentPatient(row);
        setShowPatientNote(true);
    };

    const columns = [
        {
            key: 'eye',
            label: <Eye style={{ visibility: 'hidden' }} fill={'var(--purple)'} className={styles3.listIcons} />,
            width: isMobile ? 0.01 : 0.01,
            render: (row) => (
                <div onClick={() => onCellClick(row)} className={styles.actionBtns}>
                    <Eye
                        fill={'var(--purple)'}
                        className={styles.listIcons}
                        style={{ cursor: 'pointer', width: isMobile ? '20px' : '30px', height: isMobile ? '20px' : '30px' }}
                    />
                </div>
            )
        },
        {
            key: 'admitdate',
            label: 'Admit Date',
            width: isMobile ? 0.08 : 1,
            render: (row) => isValidDate(row.admitdate) && convertToCustomDate(row.admitdate)
        },
        {
            key: 'dateofservice',
            label: 'Date of Service',
            width: isMobile ? 0.08 : 1,
            render: (row) => isValidDate(row.admitdate) && convertToCustomDate(row.admitdate)
        },
        {
            key: 'visitcode',
            label: 'Visit Codes',
            width: isMobile ? 0.08 : 1
        },
        {
            key: 'diagnoses',
            label: 'Diagnoses Codes',
            width: isMobile ? 0.2 : 1,
            render: (row) => (
                <div
                    onMouseEnter={() => handleMouseEnter(row)}
                    onMouseLeave={handleMouseLeave}
                >
                    {row.diagnoses && row.diagnoses.length > 0
                        ? row.diagnoses.map((r, index) => (
                            <span key={index}>
                                {r.code}
                                {(row.diagnoses.length - 1) !== index && ', '}
                            </span>
                        ))
                        : ' - '}
                    {hoveredRowId === row.id && row.diagnoses && row.diagnoses.length > 0 && (
                        <div className='hoverContent1'>
                            {row.diagnoses.map((d, index) => (
                                <span key={index}>
                                    {d.code} - {d.description}
                                    {(row.diagnoses.length - 1) !== index && <br />}
                                </span>
                            ))}
                        </div>
                    )}
                </div>
            )
        },
        {
            key: 'notes',
            label: 'Notes',
            width: isMobile ? 0.08 : 1,
            render: (row) => (
                <div onClick={() => onCellClick(row)} className={styles.actionBtns}>
                    <Eye
                        fill={'var(--purple)'}
                        className={styles.listIcons}
                        style={{ cursor: 'pointer', width: isMobile ? '20px' : '30px', height: isMobile ? '20px' : '30px' }}
                    />
                </div>
            )
        },
    ]

    const handleMouseEnter = (row) => {
        setHoveredRowId(row.id); // Store the row ID instead of content
    };

    const handleMouseLeave = () => {
        setHoveredRowId(null);
    };

    const handleNewNoteClick = () => {
        setShowPatientNote(true);
        setCurrentPatient({
            admission_id: patient.admission_id,
            admitdate: null,
            location: null,
            room: null,
            dischargedate: null,
            visittype: null,
            facesheetalias: null,
            owning_provider: null,
            status: 'active'
        });
    };

    const handleBack = () => {
        setShowPatientNote(false);
        setCurrentPatient(null);
    };

    return (
        <div className={styles2.container}>
            {showPatientNote ? (
                <Note
                    patient={currentPatient}
                    onBack={handleBack}
                />
            ) : (
                <>
                    <div className={styles2.newAdmissionContainer}>
                        <div onClick={handleNewNoteClick} style={{ gap: '1rem' }} className={[styles1.button, styles1.SharedVisitsBtn].join(' ')}>
                            <SharedIcon className={styles.OptionButtonsIcon} />
                            <div className={styles.actionsBtnText}>New Note</div>
                        </div>
                    </div>

                    <GenericTable columns={columns} data={[]} minWidth='1000px' />
                </>
            )}
        </div>
    );
};

export default Notes;