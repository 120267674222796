import { useState, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { HomeNavbar } from './components/reusable/HomeNavbar';
import SignUp from './components/main/SignUp';
import SignIn from './components/main/SignIn';
import PatientList from './components/main/PatientList';
import Charges from './components/main/Charges';
import SideBar from './components/reusable/Sidebar';
import Patient from './components/main/Patient';
import Profile from './components/reusable/Profile';
import Utilities from './components/single/Utilities';
import ConsultDetails from './components/single/ConsultDetails';
import AppointmentSetter from './components/reusable/AppointmentSetter';
import ConsultsTrackingTable from './components/reusable/ConsultsTrackingTable';
import EmployeeCalendar from './components/reusable/EmployeeCalendar';
import StaffManager from './components/reusable/StaffManager/StaffManager';
import { useNavigate } from 'react-router-dom';
import { setupInactivityTimer } from './helpers';
import ResetPassword from './components/main/ResetPassword';
import MacroMateClinical from './components/reusable/MacroMateClinical';
import MyProfile from './components/single/MyProfile';
import Notifications from './components/single/Notifications';
import Directory from './components/single/Directory';
import ManageAbsence from './components/single/ManageAbsence';
import SecurityAndPrivacy from './components/single/SecurityAndPrivacy';
import ChangePassword from './components/single/ChangePassword';
import SuggestionsAndFeedback from './components/reusable/SuggestionsAndFeedback';
import TrainingMaterials from './components/reusable/TrainingMaterials';

const SubApp = () => {
    const location = useLocation();
    const [flexDirection, setFlexDirection] = useState('column');
    const [isMobile, setIsMobile] = useState(false);
    const [isSafari, setIsSafari] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const ua = navigator.userAgent;
        const vendor = navigator.vendor;

        // First check if it's Opera
        const isOpera = /OPR|Opera/.test(ua) ||
            'opr' in window ||
            'opera' in window;

        // Then check for Safari mobile only if it's not Opera
        const isSafariMobile = !isOpera &&
            /iPad|iPhone|iPod/.test(ua) &&
            /Apple/.test(vendor) &&
            !/(CriOS|FxiOS|OPiOS|mercury|EdgiOS)/.test(ua) &&
            !window.MSStream;

        setIsSafari(isSafariMobile);
        const cleanup = setupInactivityTimer(navigate);
        return cleanup;
    }, [navigate]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 631) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        }
        window.addEventListener('resize', handleResize)
        handleResize();
        if (isMobile) {
            setFlexDirection('row');
        } else if (location.pathname === '/patient-list' || location.pathname === '/patient' || location.pathname === '/charges' || location.pathname.startsWith('/profile') || location.pathname === '/utilities' || location.pathname === '/calendar' || location.pathname === '/consult-details' || location.pathname === '/consults-tracking-table' || location.pathname === '/suggestions-and-feedback' || location.pathname === '/training-materials' || location.pathname === '/employee-calendar' || location.pathname === '/staff-manager' || location.pathname === '/macro-mate-clinical' || location.pathname === '/macromate-clinical') {
            setFlexDirection('row');
        } else {
            setFlexDirection('column');
        }

        return () => {
            window.removeEventListener('resize', handleResize)
        }

    }, [location.pathname])

    return (
        <>
            <HomeNavbar />
            <div className={`App ${flexDirection === 'column-reverse' ? 'flex-column-reverse' : ''} ${flexDirection === 'column' ? 'flex-column' : 'flex-row'}`} style={isSafari ? { height: 'calc(100vh - 80px)' } : {}}>
                <SideBar isMobile={isMobile} />
                <Routes>
                    <Route path="/" element={<SignIn />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/patient-list" element={<PatientList />} />
                    <Route path="/patient" element={<Patient />} />
                    <Route path="/charges" element={<Charges />} />
                    <Route path="/profile/*" element={<Profile />}>
                        <Route path="my-profile" element={<MyProfile />} />
                        <Route path="directory" element={<Directory />} />
                        <Route path="manage-absence" element={<ManageAbsence />} />
                        <Route path="security-privacy" element={<SecurityAndPrivacy />}>
                            <Route path="notification" element={<Notifications />} />
                            <Route path="change-password" element={<ChangePassword />} />
                            <Route index element={<SecurityAndPrivacy />} />
                        </Route>
                        <Route index element={<Navigate to="my-profile" />} />
                    </Route>
                    <Route path="/utilities" element={<Utilities />} />
                    <Route path="/appointment" element={<AppointmentSetter />} />
                    <Route path="/consults-tracking-table" element={<ConsultsTrackingTable />} />
                    <Route path="/consult-details" element={<ConsultDetails />} />
                    <Route path="/calendar" element={<EmployeeCalendar />} />
                    <Route path="/staff-users" element={<StaffManager />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/macromate-clinical" element={<MacroMateClinical />} />
                    <Route path="/suggestions-and-feedback" element={<SuggestionsAndFeedback />} />
                    <Route path="/training-materials" element={<TrainingMaterials />} />
                </Routes>
            </div>
        </>
    );
}

export default SubApp;
