import React, { useState, useEffect, useRef } from 'react';
import styles from '../../assets/css/Diagnosis.module.css';
import { getTokenFromLocalStorage } from '../../helpers/index';
import Toast from './Toast';
import { ReactComponent as TickPending } from '../../assets/icons/tick_pending.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

const Diagnosis = ({ handleSelectedDiagnosis, DiagnosisArray, subMode, mode }) => {
    const [showModal, setShowModal] = useState(false);
    const [descriptionInput, setDescriptionInput] = useState('');
    const [diagnosis, setDiagnosis] = useState([]);
    const [selectedDiagnosis, setSelectedDiagnosis] = useState(DiagnosisArray);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');
    const [accordion, setAccordion] = useState(true);
    const [favoritesDiagnosis, setFavoritesDiagnosis] = useState([]);
    const [notesInput, setNotesInput] = useState('');
    const hasFetchedRef = useRef(false);

    useEffect(() => {
        if (!hasFetchedRef.current) {
            fetchFavoriteDiagnosis();
            hasFetchedRef.current = true;
        }
        const delayDebounceFn = setTimeout(() => {
            handleSearch();
        }, 500); // Adjust the delay as needed
        if (DiagnosisArray.length > 0) {
            setSelectedDiagnosis(DiagnosisArray);
        }
        return () => clearTimeout(delayDebounceFn);
    }, [descriptionInput, DiagnosisArray]);

    const showSuccessToast = (message) => {
        setToastMessage(message);
        setToastType('success');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const toggleAccordion = () => {
        setAccordion(!accordion);
    }

    const showErrorToast = (message) => {
        setToastMessage(message);
        setToastType('error');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const handleAddDiagnosis = (item, mode) => {
        // check if item is already added
        if (mode === "delete") {
            const updatedDiagnosis = selectedDiagnosis.filter((diagnosis) => diagnosis.id !== item.id);
            setSelectedDiagnosis(updatedDiagnosis);
            handleSelectedDiagnosis(updatedDiagnosis);
        }
        else {
            const alreadyAdded = selectedDiagnosis.find((diagnosis) => diagnosis.id === item.id);
            if (alreadyAdded) {
                return;
            }
            const updatedDiagnosis = [...selectedDiagnosis, item];
            setSelectedDiagnosis(updatedDiagnosis);
            handleSelectedDiagnosis(updatedDiagnosis);
        }
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleNotesChange = (e) => {
        setNotesInput(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescriptionInput(e.target.value);
    };

    const fetchFavoriteDiagnosis = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/favorite-diagnoses`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
        });
        if (response.ok) {
            const data = await response.json();
            setFavoritesDiagnosis(data);
        } else {
            console.error('Error:', response.status);
        }
    }

    const handleConvertNotes = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/convert-notes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                },
                body: JSON.stringify({ description: notesInput })
            });

            if (response.ok) {
                const data = await response.json();
                const updatedDiagnosis = [...new Map([...selectedDiagnosis, ...data].map(item => [item.id, item])).values()];
                setSelectedDiagnosis(updatedDiagnosis);
                handleSelectedDiagnosis(updatedDiagnosis);

                showSuccessToast('Notes converted and codes added successfully');
            } else {
                showErrorToast('Failed to convert notes');
            }
        } catch (error) {
            showErrorToast('An error occurred while converting notes');
        }
    };

    const handleSearch = async () => {
        if (descriptionInput.length === 0) {
            setDiagnosis([]);
            return;
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/search-diagnosis?description=${descriptionInput}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
        });

        if (response.ok) {
            const data = await response.json();
            setDiagnosis(data);
        } else {
            console.error('Error:', response.status);
        }
    };

    return (
        <div className={styles.container}>
            {showToast && <Toast message={toastMessage} type={toastType} onClose={() => setShowToast(false)} />}
            <div className={styles.diagnosisHeading}>
                <h3>Diagnosis</h3>
                {(mode === 'add' || subMode === 'edit') && <div className={styles.addButton} onClick={toggleModal}>
                    <div>+Add</div>
                </div>}
            </div>
            <div className={styles.selectedDiagnosisContainer}>
                {
                    selectedDiagnosis.map((item) => (
                        <div className={styles.selectedDiagnosis} key={item.id}>
                            <div className={styles.diagnosisName}><span style={{ color: 'black' }}>{item.code}: </span> {item.description}</div>
                        </div>
                    ))
                }
            </div>
            {
                showModal && (
                    <div className="modal">
                        <div style={{ padding: '5px', border: 'none' }} className={styles.addButton} onClick={toggleModal}>Close X</div>
                        <div className="modalContent" style={{ minWidth: '40vw' }}>
                            <h3>Diagnosis</h3>
                            <div className={styles.mainContainer}>
                                <div className='search-container'>
                                    <div className={styles.inputContainer}>
                                        <div className={styles.searchContainer}>
                                            <SearchIcon className={styles.searchIcon} />
                                            <input onChange={handleDescriptionChange} placeholder="Search" className={styles.inputField} type="text" />
                                        </div>
                                    </div>
                                    <div className={styles.DiagnosisContainer}>

                                        <div className={styles.DiagnosisBlock}>
                                            <div className={styles.accordionTitle}>
                                                <p>Selected Diagnosis</p>
                                                <p className={styles.plus}></p>
                                            </div>
                                            {
                                                selectedDiagnosis.map((item, index) => {
                                                    return (
                                                        <div className={styles.DiagnosisItem} key={index}>
                                                            <div className={styles.DiagnosisItemCode}>{item.code}: {item.description}</div>
                                                            <div className={styles.DiagnosisItemDescription}> </div>
                                                            <div className={styles.checkmarkIcon}><Trash onClick={() => handleAddDiagnosis(item, "delete")} width={20} height={20} fill={'#E52B50'} /></div>
                                                        </div>
                                                    )

                                                })
                                            }

                                        </div>

                                        <div className={styles.DiagnosisBlock}>
                                            <div className={styles.accordionTitle}>
                                                <p>Diagnosis Search Results</p>
                                                <p className={styles.plus}></p>
                                            </div>
                                            {
                                                diagnosis.map((item, index) => {
                                                    return (
                                                        <div className={styles.DiagnosisItem} key={index}>
                                                            <div className={styles.DiagnosisItemCode}>{item.code}: {item.description}</div>
                                                            <div className={styles.DiagnosisItemDescription}></div>
                                                            <div className={styles.checkmarkIcon}><TickPending onClick={() => handleAddDiagnosis(item, "add")} width={20} height={20} fill={selectedDiagnosis.some((diagnosis) => diagnosis.code === item.code) ? '#5e3ae4' : 'grey'} /></div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                        <div className={styles.DiagnosisBlock}>
                                            <div className={styles.accordionTitle} onClick={() => toggleAccordion()}>
                                                <p>Favorites</p>
                                                <p className={styles.plus}>{accordion ? '-' : '+'}</p>
                                            </div>
                                            {
                                                accordion && favoritesDiagnosis.map((item, index) => {
                                                    return (
                                                        <div className={styles.DiagnosisItem} key={index}>
                                                            <div className={styles.DiagnosisItemCode}>{item.code}: {item.description}</div>
                                                            <div className={styles.DiagnosisItemDescription}> </div>
                                                            <div className={styles.checkmarkIcon}><TickPending onClick={() => handleAddDiagnosis(item, "add")} width={20} height={20} fill={selectedDiagnosis.some((diagnosis) => diagnosis.code === item.code) ? '#5e3ae4' : 'grey'} /></div>
                                                        </div>)

                                                })
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className={styles.diagnosisRightContainer}>
                                    <h4>Convert Notes to ICD-10</h4>
                                    <textarea
                                        className={styles.notesTextArea}
                                        placeholder="Paste your notes here or type for diagnosis..."
                                        value={notesInput}
                                        onChange={handleNotesChange}
                                    ></textarea>
                                    <button className={styles.convertButton} onClick={handleConvertNotes}>
                                        Convert Notes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div >
    )
}

export default Diagnosis
