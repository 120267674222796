import React, { useEffect, useState, useRef } from 'react'
import styles from '../../assets/css/ChargesTab.module.css'
import styles1 from '../../assets/css/Charges.module.css';
import { ReactComponent as PlusIcon } from '../../assets/icons/charges-plus.svg'
import { ReactComponent as TrashIcon } from '../../assets/icons/charges-delete.svg'
import { ReactComponent as SharedIcon } from '../../assets/icons/charges-shared-visit.svg'
import { ReactComponent as SubmitIcon } from '../../assets/icons/charges-submit.svg'
import { ReactComponent as VisitCodesPlusIcon } from '../../assets/icons/visit-code-plus.svg'
import ChargesTableRow from '../reusable/ChargesTableRow'
import ChargesAddPatient from '../reusable/ChargesAddPatient'
import ChargesSharedVisit from '../reusable/ChargesSharedVisits'
import { getTokenFromLocalStorage } from '../../helpers'
import ChargesVisitCodes from '../reusable/ChargesVisitCodes'
import { toast } from 'react-toastify';
import Loader from '../reusable/Loader'
import { convertToCalendarSpecificDate, isValidDate } from '../../helpers';

const ChargesTab = () => {
    const [showModal, setShowModal] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [modalName, setModalName] = useState('');
    const [patients, setPatients] = useState([]);
    const [selectedPatients, setSelectedPatients] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [date, setDate] = useState('');

    const isInitialMount = useRef(true);
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            fetchChargesPatients();
        } else {
            // This block will run on subsequent renders when patients changes
            // You can add any additional logic here if needed
        }
    }, [patients]);

    const fetchChargesPatients = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/users/charges-patients-list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenFromLocalStorage('token')}`
            }
        }).then(res => res.json()).then(data => {
            setPatients(data);
        }).catch(err => {
            console.log('err : ', err);
        });

    }

    const toggleModal = (name) => {
        setShowModal(!showModal);
        setModalName(name);
    };

    const deletePatients = () => {
        if (selectedPatients.length === 0) {
            alert('Please select at least one patient to delete');
            return;
        }
        try {
            let promiseArray = [];
            selectedPatients.forEach(patient => {
                promiseArray.push(fetch(`${process.env.REACT_APP_API_URL}/users/charges-patients-list`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getTokenFromLocalStorage('token')}`
                    },
                    body: JSON.stringify({ admission_id: patient.id })
                }));
            });

            Promise.all(promiseArray).then(async (data) => {
                await fetchChargesPatients();
                setSelectedPatients([]);
                if (isAllSelected) {
                    setIsAllSelected(false);
                }
                toast.error(' Patients deleted successfully ');
            });
        } catch (error) {
            console.error('Error deleting patients:', error);
        }
    };

    const reRenderPatients = async (message) => {
        toast.success(message);
        await fetchChargesPatients();
    }

    const submitCharges = async () => {
        try {
            if (selectedPatients.length === 0) {
                toast.error('Please select at least one patient to submit charges');
                return;
            }
            if (date === '') {
                toast.error('Please select date of service before submitting charges');
                return;
            }
            let charges_ids = [];
            selectedPatients.forEach(patientData => {
                patientData.visit_codes.forEach(vc => charges_ids.push(vc.id));
            });
            //remove duplicates from charges_ids integer array
            const removeDuplicates = (array) => [...new Set(array)];
            charges_ids = removeDuplicates(charges_ids);

            setSubmitLoading(true);

            let promiseArray = [];
            let timestamp = new Date().toISOString();

            selectedPatients.forEach((patient, index) => {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                    },
                    body: JSON.stringify({
                        patient,
                        date_of_service: date,
                        timestamp
                    })
                }
                promiseArray.push(fetch(`${process.env.REACT_APP_API_URL}/users/patient-charges-history`, requestOptions));
            });
            await Promise.all(promiseArray);
            setSubmitLoading(false);
            reRenderPatients(' Charges submitted successfully ');
        } catch (error) {
            setSubmitLoading(false);
            console.log('Error submitting charges:', error);
            toast.error('Error submitting charges');
        }
    }

    return (
        <>
            {showModal && modalName === 'add patients' && <ChargesAddPatient reRenderPatients={reRenderPatients} setShowModal={setShowModal} />}
            {showModal && modalName === 'shared visits' && <ChargesSharedVisit reRenderPatients={reRenderPatients} selectedPatients={selectedPatients} setShowModal={setShowModal} />}
            {showModal && modalName === 'visit codes' && <ChargesVisitCodes reRenderPatients={reRenderPatients} selectedPatients={selectedPatients} setShowModal={setShowModal} />}

            <div className="marginContainer">
                <div className={styles.optionsContainer}>
                    <div className={styles.partA}>
                        <div onClick={() => toggleModal('add patients')} className={[styles.button, styles.AddPatientsBtn].join(' ')}>
                            <PlusIcon className={styles.OptionButtonsIcon} />
                            <div className={styles.actionsBtnText}>Add Patients</div>
                        </div>
                        <div onClick={() => deletePatients()} className={[styles.button, styles.DeletePatientsBtn].join(' ')}>
                            <TrashIcon className={styles.OptionButtonsIcon} />
                            <div className={styles.actionsBtnText}>Delete Patients</div>
                        </div>
                    </div>
                    <div className={styles.partA}>
                        <div onClick={() => toggleModal('visit codes')} className={[styles.button, styles.VisitCodesBtn].join(' ')}>
                            <VisitCodesPlusIcon fill={'white'} className={styles.OptionButtonsIcon} />
                            <div className={styles.actionsBtnText}>Visit Codes</div>
                        </div>
                        <div onClick={() => toggleModal('shared visits')} className={[styles.button, styles.SharedVisitsBtn].join(' ')}>
                            <SharedIcon className={styles.OptionButtonsIcon} />
                            <div className={styles.actionsBtnText}>Shared Visits</div>
                        </div>
                    </div>

                </div>
                <div className={styles.tableContainer}>
                    <div className={styles.flexTable}>
                        <div id="tableHeader" className={styles.flexRow}>
                            <div className={styles.flexCell} style={{ flex: 0.1, justifyContent: 'center' }}>
                                <input checked={isAllSelected} className={styles.checkbox} type='checkbox' style={{ height: '15px', width: '15px' }} onChange={(e) => {
                                    if (e.target.checked) {
                                        setIsAllSelected(true);
                                        setSelectedPatients(patients);
                                    } else {
                                        setSelectedPatients([]);
                                        setIsAllSelected(false);
                                    }
                                }} />
                            </div>
                            <div className={[styles.flexCell, styles.flexName].join(' ')} style={{}}>Name</div>
                            <div className={[styles.flexCell].join(' ')} style={{ flex: 0.3, justifyContent: 'center' }}>Visit Code</div>
                            <div className={[styles.flexCell].join(' ')} style={{ flex: 0.2, justifyContent: 'center', fontSize: '0.65rem' }}>Shared Visit</div>
                            <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.3 }}>Admit Date</div>
                            <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.4 }}>Location</div>
                            <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.2 }}>Visit Type</div>
                            <div className={[[styles.flexCell, styles.HideMobile].join(' ')].join(' ')} style={{ flex: 0.2 }}>Status</div>
                            <div className={styles.flexCell} style={{ flex: 0.2 }}>Facesheet</div>
                        </div>
                        {
                            patients.map((patient, index) => {
                                return <ChargesTableRow
                                    patient={patient}
                                    key={index}
                                    isSelected={selectedPatients.some(item => item.id === patient.id)}
                                    onSelect={(isSelected) => {
                                        if (isSelected) {
                                            setSelectedPatients([...selectedPatients, patient]);
                                        } else {
                                            setSelectedPatients(selectedPatients.filter(item => item.id !== patient.id));
                                        }
                                    }}
                                />
                            })
                        }
                    </div>
                </div >
                <div className={styles1.dateContainer}>
                    <div className={styles.dateText}>Date Of Service:</div>
                    <input className={styles1.dateField} type="date" value={date && isValidDate(date) && convertToCalendarSpecificDate(date)} onChange={(e) => setDate(e.target.value)} />
                    {submitLoading ? <Loader /> : <div onClick={() => submitCharges()} className={[styles.button, styles.SubmitChargesBtn].join(' ')}>
                        <SubmitIcon className={styles.OptionButtonsIcon} />
                        <div className={styles.actionsBtnText}>Submit Charges</div>
                    </div>}
                </div>
            </div>
        </>
    )
}

export default ChargesTab
