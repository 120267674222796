import React from 'react'
import styles from '../../assets/css/ChargesTab.module.css'
import { convertToCustomDate, isValidDate } from '../../helpers'

const PatientChargesHistoryTableRow = ({ history }) => {

    return (
        <>
            <div style={{ padding: '0 0.5rem' }} className={styles.flexRow}>
                <div className={[styles.flexCell, styles.border].join(' ')} style={{ flex: 0.3 }}>{isValidDate(history.date_of_service) && convertToCustomDate(history.date_of_service)}</div>
                <div className={[styles.flexCell].join(' ')} style={{ flex: 0.2 }}>{isValidDate(history.admitdate) && convertToCustomDate(history.admitdate)}</div>
                <div className={[styles.flexCell].join(' ')} style={{ flex: 0.2 }}>{history.visit_codes && history.visit_codes.map(vc => vc.visit_code + ' ')}</div>
                <div className={[styles.flexCell].join(' ')} style={{ flex: 0.2 }}>{history.shared_visits.length > 0 && history.shared_visits.map(sv => sv.name + ' ')}</div>
                <div className={[styles.flexCell].join(' ')} style={{ flex: 0.2 }}>{history.name_of_user}</div>
                <div className={[styles.flexCell].join(' ')} style={{ flex: 0.2 }}>{new Date(history.timestamp).toLocaleString()}</div>
            </div >
        </>
    )
}

export default PatientChargesHistoryTableRow
