import React, { useState } from 'react';
import { Modal, Box, TextField, Button } from '@mui/material';
import nobodyAvatar from '../../assets/images/nobody-avatar.jpg';
import styles from '../../assets/css/Profile.module.css';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { getTokenFromLocalStorage, titleToDivision } from '../../helpers';
import { useOutletContext } from 'react-router-dom';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

const MyProfile = () => {
    const { userData, profilePicUrl, getUserDetails } = useOutletContext();
    const [openModal, setOpenModal] = useState(false);
    const [activeSection, setActiveSection] = useState(null);
    const [newProfilePic, setNewProfilePic] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [division, setDivision] = useState('');
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        title: '',
        phone: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        email: '',
        company_name: '',
    });

    // Update the handleFileChange function
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

        if (!allowedExtensions.exec(file.name)) {
            alert('Invalid image file');
        } else {
            setNewProfilePic(file);
            setSelectedFileName(file.name);
        }
    };

    const handleTitleChange = (e) => {
        const selectedTitle = e.target.value;
        const newDivision = titleToDivision[selectedTitle];
        setFormData({
            ...formData,
            title: selectedTitle,
            division: newDivision
        });
        setDivision(newDivision);
    };

    const handleOpenModal = (section) => {
        setFormData({
            firstname: userData?.firstname || '',
            lastname: userData?.lastname || '',
            title: userData?.title || '',
            phone: userData?.phone || '',
            street: userData?.street || '',
            city: userData?.city || '',
            state: userData?.state || '',
            zipcode: userData?.zipcode || '',
            email: userData?.email || '',
            division: userData?.division || '',
            company_name: userData?.company_name || ''
        });
        setActiveSection(section);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setActiveSection(null);
        setNewProfilePic(null);
    };

    const handleSave = async () => {
        try {

            if (newProfilePic && userData.profile_pic) {
                const deleteResponse = await fetch(`${process.env.REACT_APP_API_URL}/users/delete-profile-pic`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                    },
                    body: JSON.stringify({
                        id: userData.id,
                        profile_pic: userData.profile_pic
                    })
                });

                if (!deleteResponse.ok) {
                    console.error('Failed to delete old profile picture');
                    return;
                }
            }

            const formDataToSend = new FormData();
            Object.keys(formData).forEach(key => {
                formDataToSend.append(key, formData[key]);
            });
            if (newProfilePic) {
                formDataToSend.append('profilePic', newProfilePic);
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/user-details`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                },
                body: formDataToSend
            });

            if (response.ok) {
                handleCloseModal();
                // Refresh user data after successful update
                await getUserDetails();
            }
        } catch (error) {
            console.error('Error updating user details:', error);
        }
    };

    const renderModalContent = () => {
        switch (activeSection) {
            case 'profile':
                return (
                    <>
                        <h2>Edit Profile</h2>
                        <div className={styles.fileInputContainer}>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id="profile-pic-input"
                            />
                            <label htmlFor="profile-pic-input">
                                <Button variant="contained" component="span">
                                    Choose Profile Picture
                                </Button>
                            </label>
                            {selectedFileName && <span>{selectedFileName}</span>}
                        </div>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="First Name"
                            value={formData.firstname}
                            onChange={(e) => setFormData({ ...formData, firstname: e.target.value })}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Last Name"
                            value={formData.lastname}
                            onChange={(e) => setFormData({ ...formData, lastname: e.target.value })}
                        />
                        <TextField
                            select
                            fullWidth
                            margin="normal"
                            label="Title"
                            value={formData.title}
                            onChange={handleTitleChange}
                            SelectProps={{
                                native: true,
                            }}
                        >
                            <option value="" disabled>Select Title</option>
                            <option value="Physician">Physician</option>
                            <option value="Nurse Practitioner">Nurse Practitioner</option>
                            <option value="Physician's Assistant">Physician's Assistant</option>
                            <option value="Operations">Operations</option>
                            <option value="IT Admin">IT Admin</option>
                            <option value="Hospital Staff">Hospital Staff</option>
                        </TextField>
                        <TextField
                            select
                            fullWidth
                            margin="normal"
                            label="Company Name"
                            value={formData.company_name === "Virginia Rehab Physicians" ? "Virginia Rehab Physicians" : "Others"}
                            onChange={(e) => {
                                const value = e.target.value;
                                setFormData({
                                    ...formData,
                                    company_name: value === "Others" ? "" : value
                                });
                            }}
                            SelectProps={{
                                native: true,
                            }}
                        >
                            <option value="Virginia Rehab Physicians">Virginia Rehab Physicians</option>
                            <option value="Others">Others</option>
                        </TextField>

                        {formData.company_name !== "Virginia Rehab Physicians" && (
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Enter Company Name"
                                value={formData.company_name}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    company_name: e.target.value
                                })}
                            />
                        )}

                    </>
                );
            case 'contact':
                return (
                    <>
                        <h2>Edit Contact Information</h2>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Phone"
                            value={formData.phone}
                            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Email"
                            value={formData.email}
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        />
                    </>
                );
            case 'address':
                return (
                    <>
                        <h2>Edit Address</h2>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Street Address"
                            value={formData.street}
                            onChange={(e) => setFormData({ ...formData, street: e.target.value })}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="City"
                            value={formData.city}
                            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="State"
                            value={formData.state}
                            onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Zip Code"
                            value={formData.zipcode}
                            onChange={(e) => setFormData({ ...formData, zipcode: e.target.value })}
                        />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className={styles.profileContainer}>
                <div className={styles.mainheading}>
                    <h2>My Profile</h2>
                </div>

                <div className={styles.commonOuterProfileContainer}>
                    <div className={styles.imageNameContainer}>
                        <div className={styles.imageContainer}>
                            <div className={styles.profileImage}>
                                <img src={profilePicUrl || nobodyAvatar} alt="" />
                            </div>
                        </div>
                        <div className={styles.nameContainer}>
                            <h3>{userData ? `${userData.firstname} ${userData.lastname}` : 'Loading...'}</h3>
                            <span>{userData?.title}</span>
                            <span style={{ color: 'grey', fontSize: '14px' }}>{userData?.company_name}</span>
                        </div>
                    </div>
                    <div className={styles.editButtonContainer}>
                        {userData && (
                            <button className={styles.editButton} onClick={() => handleOpenModal('profile')}>
                                <EditIcon width='16' height="16" fill={'black'} />
                                <span>Edit</span>
                            </button>
                        )}
                    </div>
                </div>

                <div className={styles.commonOuterProfileContainer}>
                    <div className={styles.personalInformationContainer}>
                        <div className={styles.personalInformationheading}>
                            <h3>Contact information</h3>
                        </div>
                        <div className={`${styles.infoRow} ${styles.contactInfoRow}`}>
                            <div>
                                <label>Email Address</label>
                                <p>{userData?.email}</p>
                            </div>
                            <div>
                                <label>Phone Number</label>
                                <p>{userData?.phone}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.editButtonContainer}>
                        {userData && (
                            <button className={styles.editButton} onClick={() => handleOpenModal('contact')}>
                                <EditIcon width='16' height="16" fill={'black'} />
                                <span>Edit</span>
                            </button>
                        )}
                    </div>
                </div>

                <div className={styles.commonOuterProfileContainer}>
                    <div className={styles.personalInformationContainer}>
                        <div className={styles.personalInformationheading}>
                            <h3>Address</h3>
                        </div>
                        <div className={`${styles.infoRow} ${styles.addressInfoRow}`}>
                            <div>
                                <label>Street Address</label>
                                <p>{userData?.street}</p>
                            </div>
                            <div>
                                <label>City</label>
                                <p>{userData?.city}</p>
                            </div>
                        </div>
                        <div className={`${styles.infoRow} ${styles.addressInfoRow}`}>
                            <div>
                                <label>State</label>
                                <p>{userData?.state}</p>
                            </div>
                            <div>
                                <label>Zip Code</label>
                                <p>{userData?.zipcode}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.editButtonContainer}>
                        {userData && (
                            <button className={styles.editButton} onClick={() => handleOpenModal('address')}>
                                <EditIcon width='16' height="16" fill={'black'} />
                                <span>Edit</span>
                            </button>
                        )}
                    </div>
                </div>

                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                >
                    <Box sx={modalStyle}>
                        {renderModalContent()}
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                            <Button variant="outlined" onClick={handleCloseModal}>Cancel</Button>
                            <Button variant="contained" onClick={handleSave}>Save</Button>
                        </Box>
                    </Box>
                </Modal>
            </div>
        </>
    );
};

export default MyProfile;
