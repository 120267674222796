import React, { useState } from 'react';
import ICDFavorites from '../reusable/ICDFavorites';
import styles from '../../assets/css/Utilities.module.css'
import { ReactComponent as MacroMateClinicalIcon } from '../../assets/icons/MacroMateClinical.svg';
import { ReactComponent as AppointmentSetterIcon } from '../../assets/icons/clock.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as ShortCodesIcon } from '../../assets/icons/pencil.svg';
import { ReactComponent as ConsultationIcon } from '../../assets/icons/Consultation.svg';
import { ReactComponent as RightArrowHalfIcon } from '../../assets/icons/right-arrow-half.svg';
import { ReactComponent as FeedbackIcon } from '../../assets/icons/feedback.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import ICDShortCodes from '../reusable/ICDShortCodes';
import MacroMateClinical from '../reusable/MacroMateClinical';
import EmployeeCalendar from '../reusable/EmployeeCalendar';
import AppointmentSetter from '../reusable/AppointmentSetter';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import ConsultsTrackingTable from '../reusable/ConsultsTrackingTable';
import SuggestionsAndFeedback from '../reusable/SuggestionsAndFeedback';
import TrainingMaterials from '../reusable/TrainingMaterials';

const Utilities = () => {
    const [activeTab, setActiveTab] = useState('');
    const navigate = useNavigate();

    return (
        <div className="UtilitiesContainer">
            <div className={`UtilitiesWhiteContainer whiteContainerWide`}>
                {
                    (activeTab === 'employeeCalendar' && <EmployeeCalendar />) ||
                    (activeTab === 'MacroMateClinical' && <MacroMateClinical />) ||
                    (activeTab === 'consultsTracking' && <ConsultsTrackingTable />) ||
                    (activeTab === 'SuggestionsAndFeedback' && <SuggestionsAndFeedback />) ||
                    (activeTab === 'trainingMaterials' && <TrainingMaterials />)
                }

                {activeTab === '' && <div className={activeTab !== '' ? [styles.active, styles.utilitiesContainer].join(' ') : styles.utilitiesContainer}>

                    {
                        activeTab === '' && <div className={styles.optionContainer} onClick={() => {
                            sessionStorage.setItem('refreshScheduler', 'true');
                            navigate('/calendar');
                        }}>
                            <div className={styles.options}>
                                <CalendarIcon fill={'grey'} />
                                <p>Team Calendar</p>
                            </div>
                            <div className={styles.options}>
                                <RightArrowHalfIcon stroke={'var(--purple)'} height={20} width={20} />
                            </div>
                        </div>
                    }

                    {
                        activeTab === '' && <div className={styles.optionContainer} onClick={() => navigate('/macromate-clinical')}>
                            <div className={styles.options}>
                                <MacroMateClinicalIcon stroke={'grey'} />
                                <p>MacroMate Clinical</p>
                            </div>
                            <div className={styles.options}>
                                <RightArrowHalfIcon stroke={'var(--purple)'} height={20} width={20} />
                            </div>
                        </div>
                    }

                    {
                        activeTab === '' && <div className={styles.optionContainer} onClick={() => navigate('/consults-tracking-table')}>
                            <div className={styles.options}>
                                <ConsultationIcon />
                                <p>Consult Tracker</p>
                            </div>
                            <div className={styles.options}>
                                <RightArrowHalfIcon stroke={'var(--purple)'} height={20} width={20} />
                            </div>
                        </div>
                    }
                    {
                        activeTab === '' && <div className={styles.optionContainer} onClick={() => navigate('/suggestions-and-feedback')}>
                            <div className={styles.options}>
                                <FeedbackIcon width={20} height={20} fill="#808080" />
                                <p>Suggestions and Feedback</p>
                            </div>
                            <div className={styles.options}>
                                <RightArrowHalfIcon stroke={'var(--purple)'} height={20} width={20} />
                            </div>
                        </div>
                    }
                    {
                        activeTab === '' && <div className={styles.optionContainer} onClick={() => navigate('/training-materials')}>
                            <div className={styles.options}>
                                <ConsultationIcon />
                                <p>Training Materials</p>
                            </div>
                            <div className={styles.options}>
                                <RightArrowHalfIcon stroke={'var(--purple)'} height={20} width={20} />
                            </div>
                        </div>
                    }

                </div>}


            </div>
        </div >
    );
};

export default Utilities;