import React, { useEffect, useRef, useState } from 'react';
import styles from '../../assets/css/MacroMateClinical.module.css';
import { getTokenFromLocalStorage } from '../../helpers';
import { toast } from 'react-toastify';
import Loader from './Loader';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { useNavigate } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const MacroMateClinical = () => {
    const navigate = useNavigate();
    const [shortcut, setShortcut] = useState('');
    const AllShortCuts = useRef([]);
    const [RenderExpansionData, setRenderExpansionData] = useState([]);
    const [expandedText, setExpandedText] = useState('');
    const [listExpandedText, setListExpandedText] = useState([]);
    const [editingId, setEditingId] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [loading, setLoading] = useState(false);
    const [expansionsOpen, setExpansionsOpen] = useState(false);
    const [AddExpansionsOpen, setAddExpansionsOpen] = useState(false);

    useEffect(() => {
        fetchShortCuts();
    }, []);

    const handleAccordionClick = (event) => {
        if (event.target.tagName === 'BUTTON') {
            return;
        }
        setExpansionsOpen(!expansionsOpen);
    };

    const handleAccordionClickForAddExpansions = (event) => {
        if (event.target.tagName === 'BUTTON' || event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
            return;
        }
        setAddExpansionsOpen(!AddExpansionsOpen);
    };

    const handleImportClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'application/json';
        fileInput.onchange = handleFileChange;
        fileInput.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        readJsonFile(file);
    };

    const readJsonFile = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const jsonData = JSON.parse(event.target.result);
            importExpansions(jsonData);
        };
        reader.readAsText(file);
    };

    const importExpansions = async (jsonData) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
            body: JSON.stringify(jsonData)
        }
        try {
            setLoading(true);
            const response = await fetch(process.env.REACT_APP_API_URL + '/users/import-expansions', requestOptions);
            const data = await response.json();
            if (response.ok) {
                toast.success(data.message);
                fetchShortCuts();
                console.log(data.message);
            }
            else {
                toast.error(data.message);
                console.log('error');
            }
        } catch (error) {
            console.log(error.message);
        }
        finally {
            setLoading(false);
        }
    }


    const fetchShortCuts = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/text-expander`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                },
            });
            if (response.ok) {
                const data = await response.json();
                AllShortCuts.current = data;
                setRenderExpansionData(data);
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const addExpansion = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
            body: JSON.stringify({ shortcut, expansion: expandedText, edit: false })
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/users/text-expander', requestOptions);
            const data = await response.json();
            if (response.ok) {
                toast.success('Added Successfully!');
                fetchShortCuts();
                console.log(data.message);
            }
            else {
                toast.error(data.message);
                console.log('error');
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const editExpansion = async (sc, exp, edit = true) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
            body: JSON.stringify({ shortcut: sc, expansion: exp, edit })
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/users/text-expander', requestOptions);
            const data = await response.json();
            if (response.ok) {
                toast.success('Edited Successfully!');
                setEditingId('');
                fetchShortCuts();
                console.log(data.message);
            }
            else {
                toast.error(data.message);
                console.log('error');
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const searchShortCut = async () => {
        let textarea = document.getElementById("inputText");
        let cursorPosition = textarea.selectionStart;
        let textBeforeCursor = textarea.value.substring(0, cursorPosition);
        let textAfterCursor = textarea.value.substring(cursorPosition);

        AllShortCuts.current.forEach(sc => {
            if (textBeforeCursor.endsWith(sc.shortcut)) {
                textBeforeCursor = textBeforeCursor.substring(0, textBeforeCursor.length - sc.shortcut.length) + sc.expansion;
                textarea.value = (textBeforeCursor + textAfterCursor).replace(/<br>/g, '\n');
                textarea.selectionStart = textarea.selectionEnd = textBeforeCursor.length;
            }
        })
    }
    const deleteExpansion = async (id) => {
        // e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/text-expander/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                },
            });
            if (response.ok) {
                const data = await response.json();
                toast.success(data.message);
                fetchShortCuts();
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleExport = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/export-expansions`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                },
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'text_expansions.json';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const sortExpansions = () => {
        const sortedExpansions = [...AllShortCuts.current].sort((a, b) => {
            const comparison = a.shortcut.localeCompare(b.shortcut);
            return sortOrder === 'asc' ? comparison : -comparison;
        });

        setRenderExpansionData(sortedExpansions);
        setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
    }

    const autoResize = (e) => {
        const textarea = e.target;
        const scrollPos = window.pageYOffset || document.documentElement.scrollTop;
        if (textarea.scrollHeight > textarea.clientHeight) {
            textarea.style.height = textarea.scrollHeight + 'px';
            window.scrollTo(0, scrollPos);
        }
    }

    return (
        <div className="UtilitiesContainer">
            <div className='NavigationContainer'>
                <BackIcon onClick={() => { navigate(-1) }} className="back-button" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} className="UtilitiesMainContainer whiteContainerWide">
                <div style={{ height: '100%' }}>
                    <div className={styles.container}>
                        <div className={styles.headerContainer}>
                            <h1 className={styles.colorBlue}>MacroMate Clinical</h1>
                        </div>
                        <textarea style={{ height: '100%' }} onChange={(e) => { searchShortCut(e.target.value); autoResize(e); }} onInput={autoResize} disabled={false} className={styles.textClass} id="inputText" placeholder="Type your text here..."></textarea>
                    </div >
                </div>

                <div className={styles.subContainer}>
                    <Accordion
                        expanded={AddExpansionsOpen}
                        onClick={handleAccordionClickForAddExpansions}
                        sx={{
                            border: '1px solid #008CBA',
                            '& .MuiAccordionSummary-content': {
                                color: '#008CBA'
                            }
                        }}>
                        <div className={styles.accordionMainContainer}>
                            <div className={styles.accordionSubContainer}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="add-expansion-content"
                                    id="add-expansion-header"
                                >
                                    <Typography>Add New Expansion</Typography>
                                </AccordionSummary>
                            </div>
                        </div>
                        <AccordionDetails>
                            <label for="shortcut">Shortcut:</label>
                            <input className={styles.textClass} onChange={(e) => setShortcut(e.target.value)} value={shortcut} type="text" id="shortcut" placeholder=".shortcut" />
                            <br />
                            <label for="expansion">Expansion:</label>
                            <textarea className={styles.textClass} onChange={(e) => setExpandedText(e.target.value)} value={expandedText} id="expansion" placeholder="Expanded text"></textarea>
                            <br />
                            <div className={styles.buttonContainer}>
                                <button className={styles.buttonClass} onClick={addExpansion}>Add Expansion</button>
                                <button onClick={() => handleImportClick()} className={styles.buttonClass}>{loading ? <Loader height={'17px'} width={'17px'} /> : 'Import Expansions'}</button>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        onClick={handleAccordionClick}
                        expanded={expansionsOpen}
                        sx={{
                            border: '1px solid #008CBA',
                            '& .MuiAccordionSummary-content': {
                                color: '#008CBA'
                            }
                        }}
                    >
                        <div className={styles.accordionMainContainer}>
                            <div className={styles.accordionSubContainer}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="expansions-content"
                                    id="expansions-header"
                                    sx={{
                                        margin: '0',
                                        minHeight: '3rem !important'
                                    }}
                                >
                                    <Typography>Current Expansions</Typography>
                                </AccordionSummary>
                            </div>
                        </div>
                        <AccordionDetails>
                            <div className={styles.buttonContainer}>
                                <button className={styles.buttonClass} onClick={sortExpansions}>Sort Alphabetically</button>
                                <button className={styles.buttonClass} onClick={() => handleExport()}>Export Expansions</button>
                            </div>
                            <div>
                                {RenderExpansionData.map((item, index) => {
                                    return <li key={index}>
                                        <div style={{ fontSize: '0.8rem' }}>
                                            <strong>Shortcut:</strong>{item.shortcut}<br />
                                            <strong>Expansion:</strong>
                                            <span className={styles.expansionText}>{editingId !== item.id && item.expansion}</span>
                                            {editingId === item.id && <textarea onChange={(e) => setListExpandedText(e.target.value)} className={styles.editInput}>{item.expansion}</textarea>}
                                        </div>
                                        <div className={styles.editButtons}>
                                            <button onClick={() => setEditingId(item.id)} className={styles.editButton}>Edit</button>
                                            <button onClick={() => deleteExpansion(item.id)}>Remove</button>
                                            {editingId === item.id && <button onClick={() => editExpansion(item.shortcut, listExpandedText)} className={styles.saveButton}>Save</button>}
                                            {editingId === item.id && <button onClick={() => setEditingId('')} className={styles.cancelButton}>Cancel</button>}
                                        </div>
                                    </li>
                                })}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </div >
    );
};

export default MacroMateClinical;