import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import styles from '../../assets/css/Patient.module.css'
import Diagnosis from '../reusable/Diagnosis';
import { convertToCalendarSpecificDate, isValidDate, handleDates, saveDiagnosis, addPatient, addPatientIdToChargesPage, extractAgeFromDob, getTokenFromLocalStorage, updateCommonPatientDetails, formatProviderName, authorizedTitles } from '../../helpers/index'
import Loader from '../reusable/Loader';

const PatientDetails = ({ patient, subMode, mode, selectedDiagnosisProps = [], autoFillChoice = false }) => {
    let id;
    if (patient) {
        id = patient.id;
        patient.status === null && (patient.status = 'active');
    }
    const [firstname, setFirstName] = useState(patient && patient.firstname ? patient.firstname : null)
    const [lastname, setLastName] = useState(patient && patient.lastname ? patient.lastname : null)
    const [middlename, setMiddleName] = useState(patient && patient.middlename ? patient.middlename : null)
    const [gender, setGender] = useState(patient && patient.gender ? patient.gender.toLowerCase() : null)
    const [dateofbirth, setDob] = useState(patient && patient.dateofbirth ? convertToCalendarSpecificDate(patient.dateofbirth) : null)
    const [room, setRoom] = useState(patient && patient.room ? patient.room : null)
    const [location, setLocation] = useState(patient && patient.location ? patient.location : null)
    const [admitdate, setAdmitDate] = useState(patient && patient.admitdate ? convertToCalendarSpecificDate(patient.admitdate) : null)
    const [dischargedate, setDischargeDate] = useState(patient && patient.dischargedate ? convertToCalendarSpecificDate(patient.dischargedate) : null)
    const [visittype, setVisitType] = useState(patient && patient.visittype ? patient.visittype : null)
    const [facesheetalias, setFacesheetAlias] = useState(patient && patient.facesheetalias ? patient.facesheetalias : null)
    const [owningProvider, setOwningProvider] = useState(patient && patient.owning_provider_id ? patient.owning_provider_id : null)
    const [status, setStatus] = useState(patient && patient.status && patient.status ? patient.status : 'active')
    const [loading, setLoading] = useState(false)
    const [selectedDiagnosis, setSelectedDiagnosis] = useState(selectedDiagnosisProps);
    const [addPatientToChargesPage, setAddPatientToChargesPage] = useState(true)
    const [authorizedProviders, setAuthorizedProviders] = useState([]);

    const fetchAuthorizedProviders = useCallback(async () => {
        try {
            const requestOptions = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                }
            };
            const [userResponse, providersResponse] = await Promise.all([
                fetch(`${process.env.REACT_APP_API_URL}/users/user-details`, requestOptions),
                fetch(`${process.env.REACT_APP_API_URL}/users/authorized-providers`, requestOptions)
            ]);

            const userData = await userResponse.json();
            const providers = await providersResponse.json();

            if (providersResponse.ok && userResponse.ok) {
                setAuthorizedProviders(providers);
                if (mode === 'add') {
                    if (subMode !== 'addMultiplePatients') {
                        if (authorizedTitles.includes(userData.title)) {
                            setOwningProvider(userData.id);
                            handlePatientOnChange('owning_provider_id', userData.id);
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        fetchAuthorizedProviders();
    }, [fetchAuthorizedProviders]);

    const handleSelectedDiagnosis = (diagArray) => {
        patient.selectedDiagnosis = diagArray;
        setSelectedDiagnosis(diagArray);
    };

    const submitForm = async () => {

        if (firstname === null || firstname === '') {
            toast.error('Please enter first name');
            return;
        }

        if (lastname === null || lastname === '') {
            toast.error('Please enter last name');
            return;
        }

        if (dateofbirth === null || dateofbirth === '') {
            toast.error('Please select date of birth');
            return;
        }

        if (admitdate === null || admitdate === '') {
            toast.error('Please select admit date');
            return;
        }
        if (visittype === null) {
            toast.error('Please select visit type');
            return;
        }
        if (location === null || location === '') {
            toast.error('Please select place of service');
            return;
        }

        if (id === '') {
            toast.error('Patient ID is missing');
            return;
        }

        if (owningProvider === null || owningProvider === '') {
            toast.error('Please select owning provider');
            return;
        }

        try {
            setLoading(true)
            const response = await addPatient(id, firstname, lastname, middlename, gender, dateofbirth, room, location, admitdate, dischargedate, visittype, status, facesheetalias, owningProvider);
            const rawData = response;
            const jsonResponse = await rawData.json();
            if (jsonResponse.success === true) {
                toast.success('Patient updated successfully');
                const result = await saveDiagnosis(jsonResponse.id, selectedDiagnosis);
                if (result) {
                    if (selectedDiagnosis.length > 0) {
                        toast.success('Diagnosis Saved!');
                    }
                }
                else {
                    toast.error('Failed to save diagnosis');
                }
                if (addPatientToChargesPage) {
                    const result = await addPatientIdToChargesPage(jsonResponse.id);
                    const response = await result.json();
                    if (result.ok) {
                        toast.success('Patient added to charges page');
                    }
                    else {
                        toast.error(response.message);
                    }
                }
                setLoading(false);
            } else {
                setLoading(false);
                toast.error('Failed to update patient');
            }
        } catch (error) {
            setLoading(false);
            toast.error('An error occurred while updating patient');
        }
    }

    const handlePatientOnChange = (patient_key, value) => {

        if (patient_key === 'firstname') {
            setFirstName(value);
            patient.firstname = value;
        }

        if (patient_key === 'lastname') {
            setLastName(value);
            patient.lastname = value;
        }

        if (patient_key === 'middlename') {
            setMiddleName(value);
            patient.middlename = value;
        }

        if (patient_key === 'dateofbirth') {
            setDob(value);
            value = handleDates(dateofbirth, mode, subMode);
            patient.dob = value;
        }

        if (patient_key === 'gender') {
            setGender(value);
            patient.gender = value;
        }

        if (patient_key === 'room') {
            setRoom(value);
            patient.room = value;
        }
        if (patient_key === 'location') {
            setLocation(value);
            patient.location = value;
        }
        if (patient_key === 'admitdate') {
            setAdmitDate(value);
            value = handleDates(admitdate);
            patient.admitdate = value;
        }
        if (patient_key === 'dischargedate') {
            setDischargeDate(value);
            value = handleDates(dischargedate);
            patient.dischargedate = value;
        }
        if (patient_key === 'visittype') {
            setVisitType(value);
            patient.visittype = value;
        }

        if (patient_key === 'status') {
            setStatus(value);
            patient.status = value;
        }

        if (patient_key === 'owning_provider_id') {
            setOwningProvider(value);
            patient.owning_provider_id = value;
        }

    };


    return (
        <div style={{ justifyContent: 'flex-start', backgroundColor: 'white', height: '100%', borderRadius: '40px' }} className={styles.content}>
            <form onSubmit={submitForm} className={styles.subContent}>
                <center><h3 style={{ marginBottom: '3rem', marginTop: '3rem' }}>Patient Details</h3></center>
                <div className={styles.columnContainer}>
                    <div className={styles.inputWrapper}>
                        <label htmlFor="firstName">First Name<span className={styles.required}>*</span></label>
                        <input
                            id="firstName"
                            className={`${styles.inputField} ${(autoFillChoice || subMode === 'addMultiplePatients') && !firstname ? styles.requiredFieldEmpty : ''
                                }`}
                            placeholder="First Name"
                            type="text"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            required
                            value={firstname ? firstname.toString().charAt(0).toUpperCase() + firstname.toString().toLowerCase().slice(1) : ''}
                            onChange={(e) => handlePatientOnChange('firstname', e.target.value)} />

                        <label htmlFor="lastName">Last Name<span className={styles.required}>*</span></label>
                        <input
                            id="lastName"
                            className={`${styles.inputField} ${(autoFillChoice || subMode === 'addMultiplePatients') && !lastname ? styles.requiredFieldEmpty : ''
                                }`}
                            placeholder="Last Name"
                            type="text"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            required
                            value={lastname ? lastname.toString().charAt(0).toUpperCase() + lastname.toString().toLowerCase().slice(1) : ''}
                            onChange={(e) => handlePatientOnChange('lastname', e.target.value)} />


                        <label htmlFor="dateOfBirth">Date of Birth<span className={styles.required}>*</span></label>
                        <input
                            id="dateOfBirth"
                            placeholder="Date of Birth"
                            type="date"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            className={`${styles.dateField} ${(autoFillChoice || subMode === 'addMultiplePatients') && !dateofbirth ? styles.requiredFieldEmpty : ''
                                }`}
                            required
                            value={dateofbirth && isValidDate(dateofbirth) ? handleDates(dateofbirth, mode, subMode) : ''}
                            onChange={(e) => handlePatientOnChange('dateofbirth', e.target.value)} />
                        {
                            mode === 'add' && <><label htmlFor="placeOfService">Place of Service<span className={styles.required}>*</span></label>
                                <select
                                    id="placeOfService"
                                    className={`${styles.selectInputField} ${(autoFillChoice || subMode === 'addMultiplePatients') && !location ? styles.requiredFieldEmpty : ''
                                        }`}
                                    disabled={(mode === 'view&edit' && subMode === 'view')}
                                    required
                                    value={location ? location : ''}
                                    onChange={(e) => handlePatientOnChange('location', e.target.value)}
                                >
                                    <option value="">Select Place of Service</option>
                                    <option value="Chippenham Hospital">Chippenham Hospital</option>
                                    <option value="Encompass Health Rehabilitation Hospital of Petersburg">Encompass Health Rehabilitation Hospital of Petersburg</option>
                                    <option value="Henrico Doctors Hospital">Henrico Doctors Hospital</option>
                                    <option value="Johnston Willis Hospital">Johnston Willis Hospital</option>
                                    <option value="Parham Doctors Hospital">Parham Doctors Hospital</option>
                                    <option value="Southside Regional Medical Center">Southside Regional Medical Center</option>
                                </select>
                            </>
                        }
                        {mode === 'add' && <>



                            <label htmlFor="admitDate">Admit Date<span className={styles.required}>*</span></label>
                            <input
                                id="admitDate"
                                className={`${styles.dateField} ${(autoFillChoice || subMode === 'addMultiplePatients') && !admitdate ? styles.requiredFieldEmpty : ''
                                    }`}
                                placeholder="Admit Date"
                                type="date"
                                disabled={(mode === 'view&edit' && subMode === 'view')}
                                required
                                value={admitdate && isValidDate(admitdate) ? handleDates(admitdate, mode, subMode) : ''}
                                onChange={(e) => handlePatientOnChange('admitdate', e.target.value)} />
                        </>
                        }
                        {
                            mode === 'add' &&
                            <div style={{ marginBottom: '1.5rem', marginTop: '1rem', display: 'flex', flexDirection: 'column' }}>
                                <label htmlFor="owningProvider">Owning Provider<span className={styles.required}>*</span></label>
                                <select
                                    style={{ border: 0, outline: 0 }}
                                    className={`${styles.selectInputField} ${(autoFillChoice || subMode === 'addMultiplePatients') && !owningProvider ? styles.requiredFieldEmpty : ''
                                        }`}
                                    disabled={(mode === 'view&edit' && subMode === 'view')}
                                    value={owningProvider ? owningProvider : ''}
                                    onChange={(e) => handlePatientOnChange('owning_provider_id', e.target.value)}
                                    required
                                >
                                    <option value="">Select Owning Provider</option>
                                    {authorizedProviders.map(provider => {
                                        const fullName = formatProviderName(provider);
                                        return (
                                            <option
                                                key={provider.id}
                                                value={provider.id}
                                            >
                                                {fullName}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        }

                    </div>

                    <div className={styles.inputWrapper}>
                        <label htmlFor="middleName">Middle Name</label>
                        <input
                            id="middleName"
                            className={styles.inputField}
                            placeholder="Middle Name"
                            type="text"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            value={middlename ? middlename.toString().charAt(0).toUpperCase() + middlename.toString().toLowerCase().slice(1) : ''}
                            onChange={(e) => handlePatientOnChange('middlename', e.target.value)} />


                        <label htmlFor="age">Age</label>
                        <input
                            id="age"
                            className={styles.inputField}
                            placeholder="age"
                            type="number"
                            disabled={true}
                            value={extractAgeFromDob(dateofbirth)}
                        />

                        <label htmlFor="gender">Gender</label>
                        <select
                            id="gender"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            className={styles.selectInputField}
                            value={gender ? gender : ''}
                            onChange={(e) => handlePatientOnChange('gender', e.target.value)}>
                            <option className={styles.option} value="">Select Gender</option>
                            <option className={styles.option} value="m" selected={gender === 'm' ? true : false}>Male</option>
                            <option className={styles.option} value="f" selected={gender === 'f' ? true : false}>Female</option>
                            <option className={styles.option} value="o" selected={gender === 'o' ? true : false}>Other</option>
                        </select>
                        {
                            mode === 'add' &&
                            <>
                                <label htmlFor="room">Room</label>
                                <input
                                    id="room"
                                    className={styles.inputField}
                                    placeholder="room"
                                    type="text"
                                    disabled={(mode === 'view&edit' && subMode === 'view')}
                                    value={room ? room : ''}
                                    onChange={(e) => handlePatientOnChange('room', e.target.value)} />
                            </>
                        }

                        {
                            mode === 'add' &&
                            <>
                                <label htmlFor="dischargeDate">Discharge Date</label>
                                <input
                                    id="dischargeDate"
                                    className={styles.dateField}
                                    placeholder="Discharge Date"
                                    type="date"
                                    disabled={(mode === 'view&edit' && subMode === 'view')}
                                    value={dischargedate && isValidDate(dischargedate) ? handleDates(dischargedate) : ''}
                                    onChange={(e) => handlePatientOnChange('dischargedate', e.target.value)} />
                            </>
                        }

                    </div>
                </div>

                {
                    mode === 'add' && <div className={styles.diagnosisContainer} style={{ marginBottom: '1.5rem', marginTop: '3rem' }}>
                        <Diagnosis subMode={subMode} mode={mode} DiagnosisArray={selectedDiagnosis} handleSelectedDiagnosis={handleSelectedDiagnosis} />
                    </div>
                }

                {
                    mode === 'add' &&
                    <>
                        <center><h3 style={{ marginBottom: '1.5rem', marginTop: '3rem' }}>Visit Type<span className={styles.required}>*</span></h3></center>
                        <div className={`${styles.rowContainer} ${(autoFillChoice || subMode === 'addMultiplePatients') && !visittype ? styles.requiredFieldEmptyVisitType : ''
                            }`}>
                            <div style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: '1px solid var(placeholder-grey)' }} onClick={() => { (mode === 'add' || subMode === 'edit') && handlePatientOnChange('visittype', 'inpatient') }} className={`${styles.inactiveButton} ${visittype === 'inpatient' ? styles.activeButton : ''}`}>Inpatient</div>
                            <div style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => { (mode === 'add' || subMode === 'edit') && handlePatientOnChange('visittype', 'consult') }} className={`${styles.inactiveButton} ${visittype === 'consult' ? styles.activeButton : ''}`}>Consult</div>
                        </div>
                    </>
                }

                <center><h3 style={{ marginBottom: '1.5rem', marginTop: '3rem' }}>Status</h3></center>
                <div style={{ marginBottom: '20px' }} className={styles.rowContainer}>
                    <div style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: '1px solid var(placeholder-grey)' }} onClick={() => { (mode === 'add' || subMode === 'edit') && handlePatientOnChange('status', 'active') }} className={`${styles.inactiveButton} ${status === 'active' ? styles.activeButton : ''}`}>Active</div>
                    <div style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => { (mode === 'add' || subMode === 'edit') && handlePatientOnChange('status', 'inactive') }} className={`${styles.inactiveButton} ${status === 'inactive' ? styles.activeButton : ''}`}>Inactive</div>
                </div>

                {
                    (mode === 'add') && subMode !== 'addMultiplePatients' && (
                        <div className={styles.checkboxContainer}>
                            <input type="checkbox" checked={addPatientToChargesPage} onChange={() => setAddPatientToChargesPage(!addPatientToChargesPage)} />
                            <span>Add this patient to charge page</span>
                        </div>
                    )
                }

                {loading ?
                    <div style={{ margin: '3px' }}><Loader /></div>
                    :
                    (mode === 'add' || subMode === 'edit') && subMode !== 'addMultiplePatients' &&
                    (
                        <div onClick={() => mode === 'view&edit' ? updateCommonPatientDetails(patient) : submitForm()} style={{ marginBottom: '1.5rem', marginTop: '1rem' }} className={styles.rectangleBtn}>
                            Save
                        </div>
                    )
                }
                <div style={{ paddingBottom: '100px', height: '1px', width: '1px' }}></div>

            </form>
        </div >
    )
}

export default PatientDetails
