import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTokenFromLocalStorage } from '../../helpers/index';
import styles from '../../assets/css/SuggestionsAndFeedback.module.css'
import '../../App.css';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { toast } from 'react-toastify';

const SuggestionsAndFeedback = () => {
    const navigate = useNavigate();
    const [feedback, setFeedback] = useState('');
    const [image, setImage] = useState(null);
    const [category, setCategory] = useState('general');
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('feedback', feedback);
        formData.append('category', category);
        if (image) {
            formData.append('image', image);
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/feedback/submit`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                },
                body: formData
            });

            let jsonResponse = await response.json();

            if (response.ok) {
                toast.success(jsonResponse.message);
                setFeedback('');
                setImage(null);
                fileInputRef.current.value = '';
            } else {
                toast.error(jsonResponse.message);
            }
        } catch (error) {
            toast.error('Network error occurred');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="UtilitiesContainer">
            <div className='NavigationContainer'>
                <BackIcon onClick={() => { navigate(-1) }} className="back-button" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }} className="UtilitiesMainContainer whiteContainerWide">
                <h2>Suggestions and Feedback</h2>
                <select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className={styles.categorySelect}
                    required
                >
                    <option value="bug">Bug Report</option>
                    <option value="feature">Feature Request</option>
                    <option value="general">General Feedback</option>
                </select>
                <textarea
                    style={{ display: 'flex', flex: 1 }}
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder="Describe your suggestion, feature request, or report a bug..."
                    className={styles.feedbackTextarea}
                    required
                />
                <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={(e) => setImage(e.target.files[0])}
                    className={styles.fileInput}
                />
                <button
                    type="submit"
                    onClick={handleSubmit}
                    className={styles.submitButton}
                    disabled={loading}
                >
                    {loading ? 'Submitting...' : 'Submit Feedback'}
                </button>
            </div>
        </div>
    );
};

export default SuggestionsAndFeedback;
