import React, { useEffect, useState } from 'react';
import styles from '../../assets/css/Patient.module.css';
import GenericTable from '../reusable/GenericTable.js';
import styles1 from '../../assets/css/ChargesTab.module.css';
import styles2 from '../../assets/css/Admissions.module.css';
import styles3 from '../../assets/css/PatientList.module.css';
import { ReactComponent as SharedIcon } from '../../assets/icons/charges-shared-visit.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { ReactComponent as Cross } from '../../assets/icons/facesheet_cross.svg';
import { convertToCustomDate, getTokenFromLocalStorage, isValidDate } from '../../helpers/index.js';
import Loader from '../reusable/Loader.js';
import AdmissionDetails from './AdmissionDetails.js';

const Admissions = ({ patient, subMode }) => {
    const [admissionsData, setAdmissionsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hoveredRowId, setHoveredRowId] = useState(null);
    const [showAdmissionDetails, setShowAdmissionDetails] = useState(false);
    const [currentPatient, setCurrentPatient] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 631);
    const [isNewAdmission, setIsNewAdmission] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 631);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchAdmissions = async (patient_id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/admissions?patient_id=${patient_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                }
            });
            if (response.ok) {
                const data = await response.json();
                setAdmissionsData(data);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching admissions data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAdmissions(patient.patient_id);
    }, [patient.patient_id]);

    const ViewFacesheet = (row) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenFromLocalStorage()}`
        };
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                id: row.id,
                filealias: row.facesheetalias
            })
        };

        fetch(`${process.env.REACT_APP_API_URL}/users/view-file`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setTimeout(() => {
                    window.open(data.signedUrl, '_blank');
                }, 100)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const handleMouseEnter = (row) => {
        setHoveredRowId(row.admission_id); // Store the row ID instead of content
    };

    const handleMouseLeave = () => {
        setHoveredRowId(null);
    };

    const onCellClick = (row) => {
        setIsNewAdmission(false);
        setShowAdmissionDetails(true);
        setCurrentPatient(row);
    };

    const columns = [
        {
            key: 'eye',
            label: <Eye style={{ visibility: 'hidden' }} fill={'var(--purple)'} className={styles3.listIcons} />,
            width: isMobile ? 0.01 : 0.01,
            render: (row) => (
                <div onClick={() => onCellClick(row)} className={styles.actionBtns}>
                    <Eye
                        fill={'var(--purple)'}
                        className={styles.listIcons}
                        style={{ cursor: 'pointer', width: isMobile ? '20px' : '30px', height: isMobile ? '20px' : '30px' }}
                    />
                </div>
            )
        },
        {
            key: 'admitdate',
            label: 'Admit Date',
            width: isMobile ? 0.08 : 0.15,
            render: (row) => isValidDate(row.admitdate) && convertToCustomDate(row.admitdate)
        },
        {
            key: 'location',
            label: 'Location',
            width: isMobile ? 0.2 : 0.3,
        },
        {
            key: 'owning_provider_name',
            label: 'Provider',
            width: isMobile ? 0.1 : 0.2,
        },
        {
            key: 'diagnoses',
            label: 'Diagnoses Codes',
            width: isMobile ? 0.2 : 0.2,
            render: (row) => (
                <div
                    onMouseEnter={() => handleMouseEnter(row)}
                    onMouseLeave={handleMouseLeave}
                >
                    {row.diagnoses && row.diagnoses.length > 0
                        ? row.diagnoses.map((r, index) => (
                            <span key={index}>
                                {r.code}
                                {(row.diagnoses.length - 1) !== index && ', '}
                            </span>
                        ))
                        : ' - '}
                    {hoveredRowId === row.admission_id && row.diagnoses && row.diagnoses.length > 0 && (
                        <div className='hoverContent1'>
                            {row.diagnoses.map((d, index) => (
                                <span key={index}>
                                    {d.code} - {d.description}
                                    {(row.diagnoses.length - 1) !== index && <br />}
                                </span>
                            ))}
                        </div>
                    )}
                </div>
            )
        },
        {
            key: 'visittype',
            label: 'Visit Type',
            width: isMobile ? 0.1 : 0.2,
        },
        {
            key: 'discharge_date',
            label: 'Discharge Date',
            width: isMobile ? 0.18 : 0.2,
            render: (row) => isValidDate(row.dischargedate) && convertToCustomDate(row.dischargedate)
        },
        {
            key: 'facesheet',
            label: 'Facesheet',
            width: isMobile ? 0.08 : 0.1,
            render: (row) => (
                <div className={styles.actionBtns}>
                    {row.facesheetalias && row.facesheetalias !== 'null' ? (
                        <div onClick={() => ViewFacesheet(row)} className={styles.viewBtn}>
                            View
                        </div>
                    ) : (
                        <Cross fill={'red'} />
                    )}
                </div>
            )
        },
    ];

    const handleBack = () => {
        setShowAdmissionDetails(false);
        setCurrentPatient(null);
        setIsNewAdmission(false);
        fetchAdmissions(patient.patient_id);
    };

    const handleNewAdmissionClick = () => {
        setIsNewAdmission(true);
        setShowAdmissionDetails(true);
        setCurrentPatient({
            patient_id: patient.patient_id,
            admitdate: null,
            location: null,
            room: null,
            dischargedate: null,
            visittype: null,
            facesheetalias: null,
            owning_provider_id: null,
        });
    };

    if (loading) {
        return <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}><Loader width={100} height={100} /></div>;
    }

    return (
        <div className={styles2.container}>
            {showAdmissionDetails ? (
                <AdmissionDetails
                    mode={isNewAdmission ? 'add' : 'view&edit'}
                    subMode={isNewAdmission ? 'edit' : subMode}
                    patient={currentPatient}
                    onBack={handleBack}
                />
            ) : (
                <>
                    <div className={styles2.newAdmissionContainer}>
                        <div onClick={handleNewAdmissionClick} style={{ gap: '1rem' }} className={[styles1.button, styles1.SharedVisitsBtn].join(' ')}>
                            <SharedIcon className={styles.OptionButtonsIcon} />
                            <div className={styles.actionsBtnText}>New Admission</div>
                        </div>
                    </div>
                    {admissionsData.length > 0 &&
                        <GenericTable columns={columns} data={admissionsData} minWidth='1000px' />
                    }
                </>
            )}
        </div>
    );
};

export default Admissions;
