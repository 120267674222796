import { useState, useEffect } from "react";
import { FileManager } from "@cubone/react-file-manager";
import { useNavigate } from 'react-router-dom';
import "@cubone/react-file-manager/dist/style.css";
import { getTokenFromLocalStorage } from "../../helpers";
import { toast } from 'react-toastify';
import '../../assets/css/TrainingMaterials.css';
import '../../App.css';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';

const TrainingMaterials = () => {
    const [files, setFiles] = useState([]);
    const navigate = useNavigate();
    const [hasElevatedAccess, setHasElevatedAccess] = useState(false);

    useEffect(() => {
        fetchMaterials();
        checkUserAccess();

        if (!hasElevatedAccess) {
            // Create mutation observer to watch for DOM changes
            const observer = new MutationObserver(() => {
                const uploadButton = document.querySelector('button.item-action svg[viewBox="0 0 24 24"]')?.closest('button');
                if (uploadButton) {
                    uploadButton.style.display = 'none';
                }
            });

            // Start observing the document with configured parameters
            observer.observe(document.body, {
                childList: true,
                subtree: true
            });

            // Cleanup observer on component unmount
            return () => observer.disconnect();
        }
    }, [hasElevatedAccess]);

    const handleUnauthorizedAction = () => {
        toast.error("You don't have permission to modify training materials");
        fetchMaterials();
    };

    const checkUserAccess = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/check-elevated-access`, {
                headers: {
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                }
            });
            const data = await response.json();
            setHasElevatedAccess(data.hasAccess);
        } catch (error) {
            console.error('Error checking user access:', error);
        }
    };

    const fetchMaterials = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/training-materials`, {
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                }
            });
            const data = await response.json();
            const transformedData = data.map(item => ({
                key: item.id.toString(),
                name: item.name,
                isDirectory: item.is_directory,
                size: 0,
                url: item.signed_url || null,
                updatedAt: item.updated_at,
                path: item.path,
            }));

            setFiles(transformedData);
        } catch (error) {
            console.error('Error fetching materials:', error);
        }
    };

    const handleCreateFolder = async (folderName, parentFolder) => {
        const parentPath = parentFolder?.path || "/";
        const newPath = parentPath.endsWith("/") ? `${parentPath}${folderName}` : `${parentPath}/${folderName}`;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/training-materials/folder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                },
                body: JSON.stringify({
                    folderName,
                    parentPath: newPath,
                    isDirectory: true
                })
            });

            if (response.ok) {
                const newFolder = await response.json();
                setFiles(prevFiles => [...prevFiles, {
                    ...newFolder,
                    name: folderName,
                    isDirectory: true,
                    path: newPath,
                    updatedAt: new Date().toISOString()
                }]);
            }
        } catch (error) {
            console.error('Error creating folder:', error);
        }
    };

    const handleRename = async (file, newName) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/training-materials/rename`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                },
                body: JSON.stringify({
                    fileId: file.key,
                    newName: newName,
                    isDirectory: file.isDirectory,
                    path: file.path
                })
            });

            if (response.ok) {
                fetchMaterials();
            }
        } catch (error) {
            console.error('Error renaming file/folder:', error);
        }
    };

    const handleRefresh = () => {
        fetchMaterials();
    };

    const handleDelete = async (files) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/training-materials/delete`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                },
                body: JSON.stringify({
                    fileIds: files.map(file => file.key),
                    paths: files.map(file => file.path),
                    isDirectory: files[0].isDirectory
                })
            });

            if (response.ok) {
                fetchMaterials();
            }
        } catch (error) {
            console.error('Error deleting files/folders:', error);
        }
    };

    const handlePaste = async (files, destinationFolder, operationType) => {
        const destinationPath = destinationFolder?.path || "/";

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/training-materials/paste`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                },
                body: JSON.stringify({
                    fileIds: files.map(file => file.key),
                    sourcePaths: files.map(file => file.path),
                    destinationPath,
                    operationType
                })
            });

            if (response.ok) {
                fetchMaterials();
            }
        } catch (error) {
            console.error('Error pasting files/folders:', error);
        }
    };

    const handleDownload = async (files) => {
        files.forEach(async (file) => {
            if (file.isDirectory) {
                // Download zipped folder from server
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/users/training-materials/download-folder?path=${file.path}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                        }
                    }
                );
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${file.name}.zip`;
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            } else {
                // Single file download using signed URL
                const response = await fetch(`${process.env.REACT_APP_API_URL}/users/training-materials/view?path=${file.path}`, {
                    headers: {
                        'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                    }
                });
                const data = await response.json();
                const link = document.createElement('a');
                link.href = data.signedUrl;
                link.setAttribute('download', file.name);
                link.setAttribute('target', '_blank');
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        });
    };

    const handleViewFile = async (file) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/training-materials/view?path=${file.path}`, {
                headers: {
                    Authorization: `Bearer ${getTokenFromLocalStorage()}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch file preview URL');
            }

            const data = await response.json();

            if (data.signedUrl) {
                // Open the signed URL in a new tab or return it for use in a custom preview
                window.open(data.signedUrl, '_blank');
            } else {
                toast.error('Signed URL not available');
            }
        } catch (error) {
            console.error('Error fetching file view:', error);
            toast.error('Failed to view the file');
        }
    };

    const PreviewComponent = ({ file }) => {
        const [fileUrl, setFileUrl] = useState(null);

        useEffect(() => {
            const getSignedUrl = async () => {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/users/training-materials/view?path=${file.path}`, {
                    headers: {
                        'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                    }
                });
                const data = await response.json();
                setFileUrl(data.signedUrl);
            };

            if (!file.is_directory) {
                getSignedUrl();
            }
        }, [file]);

        if (!fileUrl) return <div>Loading...</div>;

        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
            return <img src={fileUrl} alt={file.name} style={{ maxWidth: '100%' }} />;
        }
        if (['mp4', 'webm', 'ogg'].includes(fileExtension)) {
            return (
                <video controls style={{ maxWidth: '100%' }}>
                    <source src={fileUrl} type={`video/${fileExtension}`} />
                    Your browser does not support the video tag.
                </video>
            );
        }
        if (fileExtension === 'pdf') {
            return (
                <iframe
                    src={file.url}
                    width="100%"
                    height="600px"
                    title={file.name}
                    style={{ border: 'none' }}
                />
            );
        }
        if (['doc', 'docx'].includes(fileExtension)) {
            return (
                <div style={{ padding: '20px', textAlign: 'center' }}>
                    <p>This document type requires external viewing.</p>
                    <button onClick={() => window.open(file.url, '_blank')}>
                        Open Document
                    </button>
                </div>
            );
        }
        return <span>Preview not available for {file.name}</span>;
    };

    return (
        <div className="UtilitiesContainer">
            <div className='NavigationContainer'>
                <BackIcon onClick={() => { navigate(-1) }} className="back-button" />
            </div>
            <FileManager
                style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', color: '#5E3AE4' }}
                className="UtilitiesMainContainer whiteContainerWide"
                files={files}
                // onFileOpen={(file) => {
                //     if (!file.isDirectory) {
                //         handleViewFile(file);
                //     }
                // }}
                onCreateFolder={(folderName, parentFolder) =>
                    hasElevatedAccess ? handleCreateFolder(folderName, parentFolder) : handleUnauthorizedAction()}
                onRename={(file, newName) =>
                    hasElevatedAccess ? handleRename(file, newName) : handleUnauthorizedAction()}
                onRefresh={handleRefresh}
                onDelete={(files) =>
                    hasElevatedAccess ? handleDelete(files) : handleUnauthorizedAction()}
                onPaste={(files, destination, operation) =>
                    hasElevatedAccess ? handlePaste(files, destination, operation) : handleUnauthorizedAction()}
                fileUploadConfig={hasElevatedAccess ? {
                    url: `${process.env.REACT_APP_API_URL}/users/training-materials/upload`,
                    headers: {
                        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
                    },
                } : {
                    url: '',
                    beforeUpload: () => {
                        handleUnauthorizedAction();
                        return false;
                    }
                }}
                onFileUploaded={(response) => {
                    const uploadedFiles = Array.isArray(response) ? response : [response]; // Ensure response is an array
                    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]); // Add multiple files to the existing state
                    fetchMaterials();
                }}
                onFileUploading={(file, parentFolder) => {
                    const parentPath = parentFolder?.path || "/";
                    const fullPath = `${parentPath}/${file.name}`;
                    return { parentPath: fullPath };
                }}
                enableFilePreview={true}
                onDownload={handleDownload}
                filePreviewComponent={(file) => <PreviewComponent file={file} />}
            />
        </div>
    );
};

export default TrainingMaterials;