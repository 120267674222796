import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTokenFromLocalStorage } from '../../helpers/index';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/charges-plus.svg'
import styles from '../../assets/css/ConsultsTrackingTable.module.css'
import '../../App.css';
import TableRowForConsultsTracking from './TableRowForConsultsTracking';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';

const ConsultsTrackingTable = () => {
    const navigate = useNavigate();
    const [consults, setConsults] = useState([]);
    const [ConsultStatus, setConsultStatus] = useState('open');
    const [originalConsults, setOriginalConsults] = useState([]);
    const [sortOrder, setSortOrder] = useState({ column: null, order: null });
    const [searchQuery, setSearchQuery] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [hideResolved, setHideResolved] = useState(true);
    const [showColumnSelector, setShowColumnSelector] = useState(false);
    const [visibleColumns, setVisibleColumns] = useState(() => {
        const savedColumns = localStorage.getItem('consultTableColumns');

        return savedColumns ? JSON.parse(savedColumns) : {
            name: true,
            facilityName: true,
            roomNumber: true,
            dob: true,
            dateRequested: true,
            timeRequested: true,
            visitDate: true,
            assignedProvider: true,
            insuranceCarrier: true,
            rehabDiagnosis: true,
            rehabRecs: true,
            notes: true,
            initialConsultComplete: true,
            status: true
        };
    });


    const isMobile = window.innerWidth <= 630;

    useEffect(() => {
        localStorage.setItem('consultTableColumns', JSON.stringify(visibleColumns));
    }, [visibleColumns]);

    const calculateTableWidth = () => {
        const columnWidths = {
            name: 70,
            facilityName: 200,
            roomNumber: 80,
            dob: 80,
            dateRequested: 120,
            timeRequested: 120,
            visitDate: 120,
            assignedProvider: 150,
            insuranceCarrier: 150,
            rehabDiagnosis: 150,
            rehabRecs: 150,
            notes: 150,
            initialConsultComplete: 150,
            status: 150
        };

        const totalWidth = Object.entries(visibleColumns)
            .reduce((sum, [key, isVisible]) => {
                return sum + (isVisible ? columnWidths[key] : 0);
            }, 50); // 50px for the eye icon column

        return `${totalWidth}px`;
    };
    const ColumnSelector = ({ visibleColumns, setVisibleColumns, onClose }) => {
        const [tempColumns, setTempColumns] = useState(visibleColumns);

        const handleAllToggle = () => {
            const allSelected = Object.values(tempColumns).every(v => v);
            setTempColumns(Object.keys(tempColumns).reduce((acc, key) => ({
                ...acc,
                [key]: !allSelected
            }), {}));
        };

        const handleColumnToggle = (key) => {
            setTempColumns(prev => ({
                ...prev,
                [key]: !prev[key]
            }));
        };

        const handleDone = () => {
            if (Object.values(tempColumns).every(v => !v)) {
                alert('Please select at least one column');
                return;
            }
            setVisibleColumns(tempColumns);
            setShowColumnSelector(false);
        };

        return (
            <div className={[styles.mobileColumnSelector].join(' ')}>
                <div className={styles.selectorHeader}>
                    <h4>Select Visible Columns</h4>
                    <button onClick={handleDone}>Done</button>
                </div>
                <div className={styles.columnOptions}>
                    <div className={styles.columnOption}>
                        <label>
                            <input
                                type="checkbox"
                                checked={Object.values(tempColumns).every(v => v)}
                                onChange={handleAllToggle}
                            />
                            All
                        </label>
                    </div>
                    {Object.entries(tempColumns).map(([key, value]) => (
                        <div key={key} className={styles.columnOption}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={value}
                                    onChange={() => handleColumnToggle(key)}
                                />
                                {key.replace(/([A-Z])/g, ' $1')
                                    .trim()
                                    .toLowerCase()
                                    .split(' ')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ')}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    useEffect(() => {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            navigate('/signin');
        }

        fetchConsultsList().then((p) => {
            setOriginalConsults(p);
            setConsults(p.filter(consults => consults.status === 'open'));
        });
    }, [navigate]);

    const fetchConsultsList = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/consults-list`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                }
            });

            if (response.ok) {
                const data = await response.json();
                return data;
            }
        } catch (error) {
            console.error('Error fetching consults:', error);
        }
    };

    const handleSort = (column) => {
        let order = sortOrder.column === column ?
            (sortOrder.order === 'desc' ? 'asc' : 'desc') :
            'desc';

        setSortOrder({ column, order });

        const sortedConsults = [...consults].sort((a, b) => {
            if (!a[column]) return 1;
            if (!b[column]) return -1;

            if (column === 'roomnumber') {
                const parseRoom = (room) => {
                    const parts = room.match(/([A-Za-z]+|\d+|[.-])/g) || [];
                    return parts.map(part =>
                        isNaN(part) ? part : part.padStart(5, '0')
                    ).join('');
                };

                const roomA = parseRoom(a[column] || '');
                const roomB = parseRoom(b[column] || '');

                return order === 'asc' ?
                    roomA.localeCompare(roomB) :
                    roomB.localeCompare(roomA);
            }

            if (['daterequested', 'visitdate'].includes(column)) {
                const dateA = new Date(a[column]);
                const dateB = new Date(b[column]);
                return order === 'asc' ? dateA - dateB : dateB - dateA;
            }

            if (column === 'timerequested') {
                const timeA = a[column].split(':').reduce((acc, time) => (60 * acc) + +time);
                const timeB = b[column].split(':').reduce((acc, time) => (60 * acc) + +time);
                return order === 'asc' ? timeA - timeB : timeB - timeA;
            }

            const valueA = a[column].toString().toLowerCase();
            const valueB = b[column].toString().toLowerCase();

            if (valueA < valueB) return order === 'asc' ? -1 : 1;
            if (valueA > valueB) return order === 'asc' ? 1 : -1;

            const nameA = `${a.firstname} ${a.lastname}`.toLowerCase();
            const nameB = `${b.firstname} ${b.lastname}`.toLowerCase();
            return nameA.localeCompare(nameB);
        });

        setConsults(sortedConsults);
    };

    const filterConsults = (searchTerm) => {
        const filteredConsults = originalConsults.filter(consults => {

            if (ConsultStatus !== '') {
                return (
                    (consults.firstname && consults.status === ConsultStatus && consults.firstname.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (consults.middlename && consults.status === ConsultStatus && consults.middlename.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (consults.lastname && consults.status === ConsultStatus && consults.lastname.toLowerCase().includes(searchTerm.toLowerCase()))
                );
            }
            else {
                return (
                    (consults.firstname && consults.firstname.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (consults.middlename && consults.middlename.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (consults.lastname && consults.lastname.toLowerCase().includes(searchTerm.toLowerCase()))
                );
            }
        });

        setConsults(filteredConsults);
    }

    //create a function to handle the search input
    const handleSearch = (searchTerm) => {
        setSearchQuery(searchTerm);
        filterConsults(searchTerm);
    };

    const AddConsult = () => {
        navigate('/consult-details', {
            state: {
                consults: null,
                mode: 'add',
            }
        });
    }

    const toggleConsultStatus = () => {
        setHideResolved(!hideResolved);
        fetchConsultsList().then((p) => {
            setOriginalConsults(p);
            if (hideResolved) {
                setConsultStatus('');
                setConsults(p);
            } else {
                setConsultStatus('open');
                setConsults(p.filter(consults => consults.status === 'open'));
            }
        });
    };

    return (
        <div style={{ paddingBottom: 0 }} className="UtilitiesContainer">
            <div className='NavigationContainer'>
                <BackIcon onClick={() => { navigate(-1) }} className="back-button" />
            </div>
            <div style={{ overflowY: 'hidden', color: 'black', marginBottom: 0, padding: 0 }} className="UtilitiesMainContainer whiteContainerWide">

                <div className={[styles.topContainer1].join(' ')}>
                    <div className={styles.toggleContainer}>
                        <span>Edit Mode</span>
                        <div className="toggle" onClick={() => setIsEditMode(!isEditMode)}>
                            <div className={`toggle-slider ${isEditMode ? 'active' : ''}`}></div>
                        </div>
                    </div>
                </div>

                {showColumnSelector && (
                    <>
                        <div className={styles.overlay} onClick={() => setShowColumnSelector(false)} />
                        <ColumnSelector
                            visibleColumns={visibleColumns}
                            setVisibleColumns={setVisibleColumns}
                            onClose={() => setShowColumnSelector(false)}
                        />
                    </>
                )}
                <div className={[styles.topContainer, styles.HideMobile].join(' ')}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.m5}>
                        <h3>All Consults</h3>
                    </div>
                    <div className={[styles.inputField, styles.m5].join(' ')}>
                        <SearchIcon className={styles.searchIcon} />
                        <input className={styles.input} type="text" value={searchQuery} placeholder="Name search" onChange={(e) => handleSearch(e.target.value)} />
                    </div>
                    <div onClick={() => AddConsult()} className={[styles.button, styles.AddConsultsBtn].join(' ')}>
                        <PlusIcon className={styles.OptionButtonsIcon} />
                        Add Consult
                    </div>
                    <button
                        className={[styles.columnSelectorBtn].join(' ')}
                        onClick={() => setShowColumnSelector(true)}
                    >
                        Select Columns
                    </button>
                    <div className={styles.toggleContainer}>
                        <span>Hide Resolved</span>
                        <div className="toggle" onClick={() => toggleConsultStatus()}>
                            <div className={`toggle-slider ${hideResolved ? 'active' : ''}`}></div>
                        </div>
                    </div>
                </div>

                <div className={[styles.topContainer, styles.HideOnDesktop].join(' ')}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.m5}>
                        <h3>All Consults</h3>
                    </div>
                    <div onClick={() => AddConsult()} className={[styles.button, styles.AddConsultsBtn].join(' ')}>
                        <PlusIcon className={styles.OptionButtonsIcon} />
                        Add Consult
                    </div>
                    <div style={{ width: '77%', marginTop: '1rem' }} className={[styles.inputField, styles.m5].join(' ')}>
                        <SearchIcon className={styles.searchIcon} />
                        <input className={styles.input} type="text" placeholder="Name search" onChange={(e) => handleSearch(e.target.value)} />
                    </div>
                    <button
                        className={[styles.columnSelectorBtn].join(' ')}
                        onClick={() => setShowColumnSelector(true)}
                    >
                        Select Columns
                    </button>
                    <div className={styles.toggleContainer}>
                        <span>Hide Resolved</span>
                        <div className="toggle" onClick={() => toggleConsultStatus()}>
                            <div className={`toggle-slider ${hideResolved ? 'active' : ''}`}></div>
                        </div>
                    </div>
                </div>
                <div style={{ paddingTop: 0 }} className="marginContainer">
                    <div style={{ paddingBottom: '8rem' }} className={styles.tableContainer}>
                        <div className={styles.flexTable}
                            style={{ '--table-width': calculateTableWidth() }}
                        >
                            <div id="tableHeader" className={styles.flexRow}>
                                <div className={styles.flexCell} style={{ flex: '0.1', marginRight: '4px' }}></div>

                                {visibleColumns.name && (
                                    <div className={styles.flexCell} style={{ flex: isMobile ? '0.6' : '1' }} onClick={() => handleSort('lastname')}>
                                        Name {sortOrder.column === 'lastname' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                    </div>
                                )}

                                {visibleColumns.facilityName && (
                                    <div className={styles.flexCell} style={{ flex: isMobile ? '.8' : '1.4' }} onClick={() => handleSort('hospitalfacilityname')}>
                                        Facility Name {sortOrder.column === 'hospitalfacilityname' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                    </div>
                                )}

                                {visibleColumns.roomNumber && (
                                    <div className={styles.flexCell} style={{ flex: isMobile ? '0.5' : '0.8' }} onClick={() => handleSort('roomnumber')}>
                                        Room No. {sortOrder.column === 'roomnumber' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                    </div>
                                )}

                                {visibleColumns.dob && (
                                    <div className={styles.flexCell} style={{ flex: isMobile ? '0.8' : '1' }}>DOB</div>
                                )}

                                {visibleColumns.dateRequested && (
                                    <div className={styles.flexCell} style={{ flex: isMobile ? '0.8' : '1' }} onClick={() => handleSort('daterequested')}>
                                        Date Requested {sortOrder.column === 'daterequested' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                    </div>
                                )}

                                {visibleColumns.timeRequested && (
                                    <div className={styles.flexCell} style={{ flex: isMobile ? '0.8' : '1' }} onClick={() => handleSort('timerequested')}>
                                        Time Requested {sortOrder.column === 'timerequested' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                    </div>
                                )}

                                {visibleColumns.visitDate && (
                                    <div className={styles.flexCell} style={{ flex: isMobile ? '0.8' : '1' }} onClick={() => handleSort('visitdate')}>
                                        Visit Date {sortOrder.column === 'visitdate' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                    </div>
                                )}

                                {visibleColumns.assignedProvider && (
                                    <div className={styles.flexCell} style={{ flex: isMobile ? '1' : '1.2' }} onClick={() => handleSort('assignedprovider')}>
                                        Assigned Provider {sortOrder.column === 'assignedprovider' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                    </div>
                                )}

                                {visibleColumns.insuranceCarrier && (
                                    <div className={styles.flexCell} style={{ flex: isMobile ? '1' : '1.2' }} onClick={() => handleSort('insurancecarrier')}>
                                        Insurance Carrier {sortOrder.column === 'insurancecarrier' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                    </div>
                                )}

                                {visibleColumns.rehabDiagnosis && (
                                    <div className={styles.flexCell} style={{ flex: isMobile ? '1' : '1.2' }} onClick={() => handleSort('rehabdiagnosis')}>
                                        Rehab Diagnosis {sortOrder.column === 'rehabdiagnosis' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                    </div>
                                )}

                                {visibleColumns.rehabRecs && (
                                    <div className={styles.flexCell} style={{ flex: isMobile ? '1' : '1.2' }} onClick={() => handleSort('rehabrecs')}>
                                        Rehab Recommendations {sortOrder.column === 'rehabrecs' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                    </div>
                                )}

                                {visibleColumns.notes && (
                                    <div className={styles.flexCell} style={{ flex: isMobile ? '0.8' : '1' }} onClick={() => handleSort('notes')}>
                                        Notes {sortOrder.column === 'notes' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                    </div>
                                )}

                                {visibleColumns.initialConsultComplete && (
                                    <div className={styles.flexCell} style={{ flex: isMobile ? '1' : '1.2' }} onClick={() => handleSort('initialconsultcomplete')}>
                                        Initial Consult Complete {sortOrder.column === 'initialconsultcomplete' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                    </div>
                                )}

                                {visibleColumns.status && (
                                    <div className={styles.flexCell} style={{ flex: isMobile ? '0.8' : '1' }} onClick={() => handleSort('status')}>
                                        Status {sortOrder.column === 'status' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                    </div>
                                )}
                            </div>

                            {consults.length > 0 && consults.map((consults, index) => (
                                <TableRowForConsultsTracking
                                    key={index}
                                    consults={consults}
                                    isEditMode={isEditMode}
                                    setConsults={setConsults}
                                    visibleColumns={visibleColumns}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="pb5"></div>
        </div>
    );
};

export default ConsultsTrackingTable;
