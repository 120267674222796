import React, { useState, useEffect } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import styles from '../../assets/css/Profile.module.css';
import { getTokenFromLocalStorage } from '../../helpers';


const Profile = () => {
    const [userData, setUserData] = useState(null);
    const [profilePicUrl, setProfilePicUrl] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        getUserDetails();
    }, []);

    const getUserDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/user-details`, {
                headers: {
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                }
            });
            const data = await response.json();
            setUserData(data);

            if (data.profile_pic) {
                const picResponse = await fetch(`${process.env.REACT_APP_API_URL}/users/view-file`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                    },
                    body: JSON.stringify({
                        id: data.id,
                        filealias: data.profile_pic
                    })
                });
                const urlData = await picResponse.json();
                setProfilePicUrl(urlData.signedUrl);
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    return (
        <div className={styles.mainContainer} style={{ height: '100vh' }}>
            {/* Main Content for Subtab */}
            <div className={`${styles.whiteContainer} whiteContainerWide`} style={{ flexGrow: 1 }}>
                <div className={styles.tabsContainer}>
                    <NavLink
                        to="my-profile"
                        className={({ isActive }) => `${styles.tab} ${isActive ? styles.activeTab : ''}`}
                    >
                        Details
                    </NavLink>
                    <NavLink
                        to="directory"
                        className={({ isActive }) => `${styles.tab} ${isActive ? styles.activeTab : ''}`}
                    >
                        Directory
                    </NavLink>
                    <NavLink
                        to="manage-absence"
                        className={({ isActive }) => `${styles.tab} ${isActive ? styles.activeTab : ''}`}
                    >
                        Manage Absence
                    </NavLink>
                    <NavLink
                        to="security-privacy"
                        className={({ isActive }) => `${styles.tab} ${isActive ? styles.activeTab : ''}`}
                    >
                        Security and Privacy
                    </NavLink>
                </div>
                <Outlet context={{
                    userData,
                    profilePicUrl,
                    getUserDetails,
                }} />
            </div>
        </div>
    );
};

export default Profile;
