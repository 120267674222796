const ManageAbsence = () => {
    return (
        <div style={{ padding: '20px', width: '100%' }}>
            <h2>Manage Absence</h2>
            <p>Coming soon...</p>
        </div>
    );
};

export default ManageAbsence;
