import { useState, useEffect } from "react";
import Toast from "../reusable/Toast";
import styles from "../../assets/css/Signup.module.css";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import Loader from "../reusable/Loader";
import { checkAccessPassword, getTokenFromLocalStorage, titleToDivision } from "../../helpers";

const SignUp = () => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [title, setTitle] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [OtpSaved, setOtpStatus] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');
    const [division, setDivision] = useState('');
    const [companyName, setCompanyName] = useState("");
    const [showOtherCompany, setShowOtherCompany] = useState(false);
    const [otherCompanyName, setOtherCompanyName] = useState("");

    useEffect(() => {
        const hasAccess = checkAccessPassword();
        if (hasAccess) {
            setIsAuthenticated(true);
        } else {
            navigate('/');
        }
    }, [navigate]);

    if (!isAuthenticated) {
        return null;
    }

    const handleTitleChange = (e) => {
        const selectedTitle = e.target.value;
        setTitle(selectedTitle);
        setDivision(titleToDivision[selectedTitle]);
    };

    const showSuccessToast = (message) => {
        setToastMessage(message);
        setToastType('success');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const showErrorToast = (message) => {
        setToastMessage(message);
        setToastType('error');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const sendOTP = async () => {
        setLoading(true);
        setErrors({});
        const formData = new URLSearchParams();
        formData.append('email', email);
        formData.append('firstname', firstname);
        formData.append('lastname', lastname);
        formData.append('title', title);
        formData.append('phone', phone);
        formData.append('password', password);
        formData.append('confirmPassword', confirmPassword);
        formData.append('termsAccepted', termsAccepted);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/signup`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: formData.toString(),
            });

            const data = await response.json();
            if (response.ok) {
                showSuccessToast('OTP sent successfully!');
                setOtpStatus(true);
                setLoading(false);
            }
            else {
                showErrorToast(data.message);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const changeOTPSaved = () => {
        setOtpStatus(false);
    }

    const resendCode = () => {
        sendOTP();
    }

    const handleSubmitSignUp = async (event) => {
        event.preventDefault();
        let errors = {};

        if (!email.trim()) {
            errors.email = '*Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is not valid';
        }
        // else if (!email.endsWith('@gmail.com')) {
        //     errors.email = 'Email must be a Gmail address';
        // }

        if (!password) {
            errors.password = '*Password is required';
        } else if (password.length < 6 || !/\d/.test(password) || !/[a-zA-Z]/.test(password)) {
            errors.password = '*Password must be at least 6 characters long and contain both digits and letters';
        }

        if (password !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }

        if (!termsAccepted) {
            errors.termsAccepted = 'You must accept the terms and conditions';
        }

        if (!firstname) {
            errors.firstname = '*First Name is required';
        }

        if (!lastname) {
            errors.lastname = '*Last Name is required';
        }

        if (!title) {
            errors.title = '*Title is required';
        }

        if (!phone) {
            errors.phone = '*Phone is required';
        }

        if (!companyName) {
            errors.companyName = '*Company Name is required';
        }

        if (companyName === "Others" && !otherCompanyName.trim()) {
            errors.otherCompanyName = '*Company Name is required';
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
        setLoading(true);
        setErrors({});
        const formData = new URLSearchParams();
        formData.append('email', email);
        formData.append('firstname', firstname);
        formData.append('lastname', lastname);
        formData.append('title', title);
        formData.append('phone', phone);
        formData.append('password', password);
        formData.append('confirmPassword', confirmPassword);
        formData.append('termsAccepted', termsAccepted);
        formData.append('division', division);
        formData.append('companyName', companyName === "Others" ? otherCompanyName : companyName);

        if (OtpSaved === false) {
            await sendOTP();
        }
        else {
            try {
                formData.append('otp', otp);
                const response = await fetch(`${process.env.REACT_APP_API_URL}/users/verify-otp`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: formData.toString(),
                });
                const data = await response.json();
                if (data.message === 'User registered successfully') {
                    showSuccessToast('User Registered successfully!');
                    setTimeout(() => {
                        navigate('/signin');
                    }, 3000);
                }
                if (data.message === 'Invalid OTP') {
                    showErrorToast('Invalid OTP');
                }
            } catch (error) {
                console.log(error.message);
                showErrorToast(error.message);
            }
        }
    };

    return (
        <section className={styles.signupContainer}>
            <div className={styles.signupContent}>
                <form onSubmit={handleSubmitSignUp} className={styles.signupForm}>
                    {OtpSaved && loading === false && <div className="NavigationContainer">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="back-button-container">
                            <BackIcon onClick={() => { setLoading(false); setOtpStatus(false); }} style={{ height: '30px' }} className="back-button" />
                            <div className={[styles.signUpText].join(' ')}>Sign Up</div>
                            <div style={{ visibility: 'hidden' }}><BackIcon onClick={() => setLoading(false)} style={{ height: '30px' }} className="back-button" /></div>
                        </div>
                    </div>}

                    {
                        OtpSaved === false && loading === false && <div className={styles.inputWrapper}>
                            <input
                                className={styles.inputField}
                                placeholder="First Name"
                                type="text"
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                            />
                            {errors.firstname && <div className={styles.error}>{errors.firstname}</div>}
                        </div>
                    }

                    {
                        OtpSaved === false && loading === false && <div className={styles.inputWrapper}>
                            <input
                                className={styles.inputField}
                                placeholder="Last Name"
                                type="text"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                            />
                            {errors.lastname && <div className={styles.error}>{errors.lastname}</div>}
                        </div>
                    }

                    {
                        OtpSaved === false && loading === false && <div className={styles.inputWrapper}>
                            <select
                                className={styles.inputField}
                                value={title}
                                onChange={(e) => handleTitleChange(e)}
                            >
                                <option value="" disabled>Select Title</option>
                                <option value="Physician">Physician</option>
                                <option value="Nurse Practitioner">Nurse Practitioner</option>
                                <option value="Physician's Assistant">Physician's Assistant</option>
                                <option value="Operations">Operations</option>
                                <option value="IT Admin">IT Admin</option>
                                <option value="Hospital Staff">Hospital Staff</option>
                            </select>
                            {errors.title && <div className={styles.error}>{errors.title}</div>}
                        </div>
                    }

                    {
                        OtpSaved === false && loading === false && <div className={styles.inputWrapper}>
                            <input
                                className={styles.inputField}
                                placeholder="Phone Number"
                                type="tel"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                            {errors.phone && <div className={styles.error}>{errors.phone}</div>}
                        </div>
                    }

                    {
                        OtpSaved === false && loading === false && <div className={styles.inputWrapper}>
                            <input
                                className={styles.inputField}
                                placeholder="Email"
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />{errors.email && <div className={styles.error}>{errors.email}</div>}
                        </div>
                    }

                    {

                        OtpSaved === false && loading === false && <div className={styles.inputWrapper}>
                            <input
                                className={styles.inputField}
                                placeholder="Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {errors.password && <div className={styles.error}>{errors.password}</div>}
                        </div>
                    }

                    {
                        OtpSaved === false && loading === false &&
                        <div className={styles.inputWrapper}><input
                            className={styles.inputField}
                            placeholder="Confirm Password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                            {errors.confirmPassword && <div className={styles.error}>{errors.confirmPassword}</div>}
                        </div>
                    }

                    {OtpSaved === false && loading === false && (
                        <div className={styles.inputWrapper}>
                            <select
                                className={styles.inputField}
                                value={companyName}
                                onChange={(e) => {
                                    setCompanyName(e.target.value);
                                    setShowOtherCompany(e.target.value === "Others");
                                }}
                            >
                                <option value="" disabled>Select Your Company Name</option>
                                <option value="Virginia Rehab Physicians">Virginia Rehab Physicians</option>
                                <option value="Others">Others</option>
                            </select>
                            {errors.companyName && <div className={styles.error}>{errors.companyName}</div>}

                            {showOtherCompany && (
                                <>
                                    <input
                                        className={styles.inputField}
                                        style={{ marginTop: '10px' }}
                                        placeholder="Enter Company Name"
                                        type="text"
                                        value={otherCompanyName}
                                        onChange={(e) => setOtherCompanyName(e.target.value)}
                                    />
                                    {errors.otherCompanyName && <div className={styles.error}>{errors.otherCompanyName}</div>}
                                </>
                            )}
                        </div>
                    )}

                    {OtpSaved === false && loading === false && <div className={styles.inputWrapper}>
                        <div className={styles.termsCheckbox}>
                            <input
                                type="checkbox"
                                className={styles.checkbox}
                                checked={termsAccepted}
                                onChange={(e) => setTermsAccepted(e.target.checked)}
                            />
                            <div className={styles.iAcceptTerms}>
                                I Accept Terms And Conditions
                            </div>
                        </div>
                        {errors.termsAccepted && <div className={styles.error}>{errors.termsAccepted}</div>}
                    </div>}



                    {OtpSaved &&
                        <div className={styles.OtpContainer}>
                            <p className={styles.codeText}>Enter the confirmation code we sent to {email}</p>
                            <div className={styles.inputWrapper}><input className={styles.inputField} onChange={(e) => setOtp(e.target.value)} placeholder="Enter OTP" /></div>
                            <p className={styles.resendCodeText}>Didn't receive the code ? <span onClick={() => { changeOTPSaved(); resendCode(); }} style={{ color: 'var(--purple' }}><u>Resend Code</u></span></p>
                        </div>
                    }
                    <div className={styles.inputWrapper}>
                        {loading && !OtpSaved ?
                            <><div className={styles.loading}>Please wait...</div><Loader /></> :
                            <button className={styles.submitButton}>Submit</button>}
                    </div>
                </form>
                {loading === false &&
                    <div className={styles.OtpContainer}>
                        <p className={styles.codeText}>Already have an account ? <Link to='/signin'><u>Sign In</u></Link></p>
                    </div>
                }
                {showToast && <Toast message={toastMessage} type={toastType} onClose={() => setShowToast(false)} />}
            </div>
        </section >
    );
};

export default SignUp;