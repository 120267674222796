import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SubApp from './SubApp';
import './App.css';

const App = () => {

  return (
    <Router>
      <SubApp />
      <ToastContainer />
    </Router >
  );
}

export default App;
