import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Toast from '../reusable/Toast';
import styles from "../../assets/css/SignIn.module.css";

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const navigate = useNavigate();

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');

    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            showErrorToast('Passwords do not match');
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token, newPassword })
            });

            const data = await response.json();
            if (response.ok) {
                showSuccessToast('Password reset successful');
                setTimeout(() => navigate('/signin'), 2000);
            } else {
                showErrorToast(data.message || 'Password reset failed');
            }
        } catch (error) {
            showErrorToast('Network error');
        } finally {
            setLoading(false);
        }
    };

    const showSuccessToast = (message) => {
        setToastMessage(message);
        setToastType('success');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const showErrorToast = (message) => {
        setToastMessage(message);
        setToastType('error');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    return (
        <section className={styles.loginContainer}>
            <div className={styles.loginContent}>
                <form onSubmit={handleResetPassword} className={styles.loginForm}>
                    <h2>Reset Password</h2>
                    <div className={styles.inputWrapper}>
                        <input
                            className={styles.inputField}
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <input
                            className={styles.inputField}
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                    <button className={styles.submitButton} disabled={loading}>
                        {loading ? 'Resetting...' : 'Reset Password'}
                    </button>
                </form>
            </div>
            {showToast && <Toast message={toastMessage} type={toastType} onClose={() => setShowToast(false)} />}
        </section>
    );
};

export default ResetPassword;
