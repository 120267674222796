import React, { useState } from 'react';
import styles from '../../assets/css/Profile.module.css';
import { useNavigate } from 'react-router-dom';
import Toast from "../reusable/Toast";
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';

const NotificationSettings = () => {
    const [isAllowSms, setIsAllowSms] = useState(false);
    const [isAllowEmail, setIsAllowEmail] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');

    const showSuccessToast = (message) => {
        setToastMessage(message);
        setToastType('success');
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
    };

    const navigate = useNavigate();

    return (
        <>
            <div className="NavigationContainer">
                <div className="back-button-container">
                    <BackIcon onClick={() => navigate(-1)} className="back-button" />
                </div>
            </div>
            <div className={styles.commonOuterProfileContainer}>
                <div className={styles.personalInformationContainer}>
                    <div className={styles.personalInformationheading}>
                        <h3 >Notification Settings</h3>
                        <p>By opting in, you agree to receive email and/or SMS alerts with important company updates, announcements, and work-related information.</p>
                    </div>
                    <div className={styles.notificationInfoRow1}>
                        <div className={styles.switch}>
                            <h4>Email :</h4>
                            <div
                                className={`${styles.toggleSwitch} ${isAllowEmail ? styles.active : styles.inactive}`}
                                onClick={() => setIsAllowEmail(!isAllowEmail)}
                            >
                                <div className={styles.toggleButton}></div>
                                <span className={styles.toggleLabel}>
                                    {isAllowEmail ? 'Opt-In' : 'Opt-Out'}
                                </span>
                            </div>
                        </div>
                        <div className={styles.switch}>
                            <h4>SMS :</h4>
                            <div
                                className={`${styles.toggleSwitch} ${isAllowSms ? styles.active : styles.inactive}`}
                                onClick={() => setIsAllowSms(!isAllowSms)}
                            >
                                <div className={styles.toggleButton}></div>
                                <span className={styles.toggleLabel}>
                                    {isAllowSms ? 'Opt-In' : 'Opt-Out'}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.notificationInfoRow}>
                        <div className={styles.timeInputContainer}>
                            <span>Between the hours of:</span>
                            <div className={styles.timeInputWrapper}>
                                <input
                                    type="time"
                                    placeholder="Start Time"
                                    className={styles.timeInput} />
                                <span>and</span>
                                <input
                                    type="time"
                                    placeholder="End Time"
                                    className={styles.timeInput} />
                            </div>
                        </div>
                    </div>
                </div>
                {showToast && <Toast message={toastMessage} type={toastType} onClose={() => setShowToast(false)} />}
            </div>
        </>
    );
};

export default NotificationSettings;
