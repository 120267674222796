import React from 'react'
import { ReactComponent as Eye } from '../../assets/icons/eye.svg'
import styles from '../../assets/css/ConsultsTrackingTable.module.css'
import { useNavigate } from 'react-router-dom'
import { convertToCustomDate, getTokenFromLocalStorage, isValidDate } from '../../helpers'

const TableRowForConsultsTracking = ({ consults, isEditMode, setConsults, visibleColumns }) => {
    const navigate = useNavigate();
    const isMobile = window.innerWidth <= 630;

    const EditDetails = (consults) => {
        navigate('/consult-details', {
            state: {
                consults: consults,
                mode: 'view&edit',
            }
        });
    };

    const handleStatusToggle = async () => {
        const newStatus = consults.status === 'open' ? 'resolved' : 'open';
        const formData = new URLSearchParams();

        if (consults.id) formData.append('id', consults.id);
        if (consults.hospitalfacilityname) formData.append('hospitalfacilityname', consults.hospitalfacilityname);
        if (consults.roomnumber) formData.append('roomnumber', consults.roomnumber);
        if (consults.firstname) formData.append('firstname', consults.firstname);
        if (consults.lastname) formData.append('lastname', consults.lastname);
        if (consults.dob) formData.append('dob', consults.dob);
        if (consults.daterequested) formData.append('daterequested', consults.daterequested);
        if (consults.timerequested) formData.append('timerequested', consults.timerequested);
        if (consults.visitdate) formData.append('visitdate', consults.visitdate);
        if (consults.owning_provider_id) formData.append('owning_provider_id', consults.owning_provider_id);
        if (consults.insurancecarrier) formData.append('insurancecarrier', consults.insurancecarrier);
        if (consults.rehabdiagnosis) formData.append('rehabdiagnosis', consults.rehabdiagnosis);
        if (consults.rehabrecs) formData.append('rehabrecs', consults.rehabrecs);
        if (consults.notes) formData.append('notes', consults.notes);
        if (consults.initialconsultcomplete) formData.append('initialconsultcomplete', consults.initialconsultcomplete);
        formData.append('status', newStatus);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/add-or-update-consult`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer " + getTokenFromLocalStorage(),
                },
                body: formData.toString(),
            });

            if (response.ok) {
                setConsults((prevConsults) =>
                    prevConsults.map((list) =>
                        list.id === consults.id ? { ...list, status: newStatus } : list
                    )
                );
            } else {
                throw new Error('Failed to update consults status');
            }
        } catch (error) {
            console.error('Error updating status:', error);
            alert('Error updating status');
        }
    };

    function capitalizeNames(firstname, lastname) {
        const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        const capitalizedFirstName = firstname ? capitalizeFirstLetter(firstname) : '';
        const capitalizedLastName = lastname ? capitalizeFirstLetter(lastname) : '';
        return `${capitalizedLastName}, ${capitalizedFirstName}`;
    }

    return (
        <div className={styles.flexRow}>
            <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: '0.1', marginRight: '4px' }}>
                <Eye fill={'var(--purple)'} className={styles.listIcons} />
            </div>

            {visibleColumns.name && (
                <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: isMobile ? '0.6' : '1' }}>
                    {capitalizeNames(consults.firstname, consults.lastname)}
                </div>
            )}

            {visibleColumns.facilityName && (
                <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: isMobile ? '.8' : '1.4' }}>
                    {consults.hospitalfacilityname}
                </div>
            )}

            {visibleColumns.roomNumber && (
                <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: isMobile ? '0.4' : '0.8' }}>
                    {consults.roomnumber}
                </div>
            )}

            {visibleColumns.dob && (
                <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: isMobile ? '0.8' : '1' }}>
                    {isValidDate(consults.dob) && convertToCustomDate(consults.dob)}
                </div>
            )}

            {visibleColumns.dateRequested && (
                <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: isMobile ? '0.8' : '1' }}>
                    {isValidDate(consults.daterequested) && convertToCustomDate(consults.daterequested)}
                </div>
            )}

            {visibleColumns.timeRequested && (
                <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: isMobile ? '0.8' : '1' }}>
                    {consults.timerequested}
                </div>
            )}

            {visibleColumns.visitDate && (
                <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: isMobile ? '0.8' : '1' }}>
                    {isValidDate(consults.visitdate) && convertToCustomDate(consults.visitdate)}
                </div>
            )}

            {visibleColumns.assignedProvider && (
                <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: isMobile ? '1' : '1.2' }}>
                    {consults.owning_provider_name}
                </div>
            )}

            {visibleColumns.insuranceCarrier && (
                <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: isMobile ? '1' : '1.2' }}>
                    {consults.insurancecarrier}
                </div>
            )}

            {visibleColumns.rehabDiagnosis && (
                <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: isMobile ? '1' : '1.2' }}>
                    {consults.rehabdiagnosis}
                </div>
            )}

            {visibleColumns.rehabRecs && (
                <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: isMobile ? '1' : '1.2' }}>
                    {consults.rehabrecs}
                </div>
            )}

            {visibleColumns.notes && (
                <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: isMobile ? '0.8' : '1' }}>
                    {consults.notes}
                </div>
            )}

            {visibleColumns.initialConsultComplete && (
                <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: isMobile ? '1' : '1.2' }}>
                    {consults.initialconsultcomplete}
                </div>
            )}

            {visibleColumns.status && (
                <div className={styles.flexCell} style={{ flex: isMobile ? '0.8' : '1' }}>
                    {isEditMode ? (
                        <div
                            className={`${styles.toggleSwitch} ${consults.status === 'open' ? styles.active : styles.inactive}`}
                            onClick={handleStatusToggle}
                        >
                            <div className={styles.toggleButton}></div>
                            <span className={styles.toggleLabel}>
                                {consults.status === 'open' ? 'open' : 'resolved'}
                            </span>
                        </div>
                    ) : (
                        consults.status
                    )}
                </div>
            )}
        </div>
    )
};

export default TableRowForConsultsTracking
