import React, { useEffect, useState } from 'react'
import styles from '../../assets/css/Patient.module.css'
import axios from 'axios';
import Toast from '../reusable/Toast';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg'
import { getTokenFromLocalStorage } from '../../helpers/index.js'

const Facesheet = ({ handleFacesheetData, patient, subMode }) => {
    const [facesheetalias, setFacesheetAlias] = useState(patient ? patient.facesheetalias : null);
    const [id, setId] = useState(patient ? patient.id : null);
    const [facesheet, setFacesheet] = useState(null);
    const [patientData, setpatientData] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [uploadFacesheetChoice, setUploadFacesheetChoice] = useState(true);
    const [autoFillChoice, setAutoFillChoice] = useState(null);
    const [isFacesheetUploaded, setIsFacesheetUploaded] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');

    useEffect(() => {
        if (autoFillChoice !== null || uploadFacesheetChoice === false) {
            handleFacesheetData(uploadFacesheetChoice, isFacesheetUploaded, autoFillChoice, patientData);
        }
    }, [autoFillChoice, uploadFacesheetChoice, isFacesheetUploaded]);

    const showSuccessToast = (message) => {
        setToastMessage(message);
        setToastType('success');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const showErrorToast = (message) => {
        setToastMessage(message);
        setToastType('error');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;

        if (!allowedExtensions.exec(file.name)) {
            setError('Please select a valid image file (JPG, JPEG, or PNG or PDF)');
            setFacesheet(null);
        } else {
            setFacesheet(file);
            setError('');
        }
    };

    const uploadFacesheet = async (WillUploadFaceSheet) => {
        if (WillUploadFaceSheet === "True" && !facesheet) {
            setError('Please select a facesheet file');
            return;
        }

        const formData = new FormData();
        if (WillUploadFaceSheet) {
            formData.append('facesheet', facesheet);
        }
        if (id) {
            formData.append('id', id);
        }
        formData.append('WillUploadFaceSheet', WillUploadFaceSheet);

        try {
            setLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/upload-facesheet`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}` // Include the JWT token in the Authorization header
                }
            });
            setpatientData(response.data);
            setIsFacesheetUploaded(true);
            showSuccessToast('Facesheet uploaded successfully');
            if (WillUploadFaceSheet === "True") {
                setAutoFillChoice(true);
            }
        } catch (error) {
            setLoading(false);
            showErrorToast(error?.response?.data?.message || 'An error occurred while uploading the facesheet.');
            console.error(error);
        }
    };

    const handleWithoutFacesheetUpload = async () => {
        if (patient === null) {
            await uploadFacesheet("False");
        }
        setUploadFacesheetChoice(false);
    }

    const ViewFacesheet = () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenFromLocalStorage()}`
        };
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ id, filealias: facesheetalias })
        };

        fetch(`${process.env.REACT_APP_API_URL}/users/view-file`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setTimeout(() => {
                    window.open(data.signedUrl, '_blank');
                }, 100)
            })
            .catch(error => {
                console.error('Error:', error);
                showErrorToast('Network error');
                setShowToast(true);
            });
    }

    const DeleteFacesheet = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenFromLocalStorage()}`
        };
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ id, facesheetalias })
        };
        const result = await fetch(`${process.env.REACT_APP_API_URL}/users/delete-facesheet`, requestOptions);
        if (result.status === 200) {
            showSuccessToast('Facesheet deleted successfully');
            setFacesheetAlias(null);
            patient.facesheetalias = null;
            setpatientData(patient);
        } else {
            showErrorToast('Error deleting facesheet');
        }
    }

    return (
        <div className={styles.content}>
            <div className={styles.subContent}>

                {uploadFacesheetChoice === true && isFacesheetUploaded === false && (
                    <>
                        <input type="file" accept="image/*,application/pdf" onChange={handleFileChange} />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        {loading ? <div>Please wait...</div> : <div style={{ width: '200px' }} onClick={() => uploadFacesheet("True")} className="purple-btn">Upload Facesheet</div>}
                        {loading ? null : <div style={{ width: 'unset' }} onClick={() => handleWithoutFacesheetUpload()} className="purple-btn">Skip Uploading Facesheet</div>}
                    </>
                )}

            </div>
            {showToast && <Toast message={toastMessage} type={toastType} onClose={() => setShowToast(false)} />}
        </div>
    )
}

export default Facesheet
